import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../components/loading-indicator';
import Paginator from '../../../components/paginator';
import { useDebounce } from '../../../hooks/use-debounce';
import {
    ReferralStatisticItemType,
    ReferralStatisticResponseType,
    ReferralTotalResponseType,
    SaleInvestmentItemType,
    SaleInvestmentResponseType,
} from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import BonusWidget, { BonusWidgetEnum } from './components/bonus-widget';
import ReferralWidget from './components/referral-widget';
import ReferralItem from './components/referral-item';
import PeopleWidget from './components/people-widget';

const ReferralStatistic: React.FC = () => {
    const { t } = useTranslation();

    const [name, setName] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [clients, setClients] = useState<ReferralStatisticItemType[]>([]);
    const [pagination, setPagination] = useState<ReferralStatisticResponseType>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [investPagination, setInvestPagination] = useState<SaleInvestmentResponseType>();
    const [investPageIndex, setInvestPageIndex] = useState<number>(0);
    const [investments, setInvestments] = useState<SaleInvestmentItemType[]>([]);
    const [total, setTotal] = useState<ReferralTotalResponseType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string>('SK');
    const [widgetYear, setWidgetYear] = useState<number>(2021);

    const debounceSearch = useDebounce<string>(name.trim(), 600);
    const debounceUsernameSearch = useDebounce<string>(username.trim(), 600);

    useEffect(() => {
        adminService.getReferralTotal(countryCode, widgetYear).then((res) => {
            setTotal(res.data);
        });
    }, [countryCode, widgetYear]);

    const loadStatistics = (search: string, username: string, page: number) => {
        setLoading(true);
        adminService
            .getReferralStatistics(search, username, { page })
            .then((res) => {
                setClients(res.data.content);
                setPagination(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadStatistics(debounceSearch, debounceUsernameSearch, pageIndex);
    }, [pageIndex, debounceSearch, debounceUsernameSearch]);

    const loadInvestments = (userId: number, page: number) => {
        setLoading(true);
        adminService
            .getReferralInvestments(userId, { size: 10, page })
            .then((res) => {
                setInvestPageIndex(page);
                setInvestments(res.data.content);
                setInvestPagination(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateColor = (color: string, id: number) => {
        adminService.updateColor(color, id).then(() => {
            loadStatistics(debounceSearch, debounceUsernameSearch, pageIndex);
        });
    };

    return (
        <div className="statistic-container">
            <div className="search-wrapper">
                <div className="form-floating">
                    <input
                        type="text"
                        id=""
                        placeholder=" "
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                    <label htmlFor="nameSearch" className="form-label mb-2">
                        {t('common.nameSearch')}
                    </label>
                </div>
                <div className="form-floating">
                    <input
                        type="text"
                        id=""
                        placeholder=" "
                        value={username}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                    <label htmlFor="nameSearch" className="form-label mb-2">
                        Userame Search
                    </label>
                </div>
            </div>
            <div className="grid-container">
                <div className="statistic-list">
                    <div className="client-list-header ">
                        <div>Name / Refferals</div>
                        <div>Refferal</div>
                        <div>Paid out</div>
                        <div>Unpaid</div>
                    </div>
                    <Accordion defaultActiveKey="0">
                        {clients.map((i, index) => (
                            <ReferralItem
                                key={index}
                                eventKey={`key${index}`}
                                item={i}
                                investments={investments}
                                pagination={investPagination}
                                pageIndex={investPageIndex}
                                loadInvestments={loadInvestments}
                                updateColor={updateColor}
                            />
                        ))}
                        {clients.length === 0 && (
                            <div className="portfolio-empty">
                                <span>{t('affilate.empty-list')}</span>
                            </div>
                        )}
                    </Accordion>
                    <LoadingIndicator visible={loading} />
                    <Paginator
                        pageIndex={pageIndex}
                        dataLength={pagination?.totalElements}
                        onPageChanged={(newPage: number) => {
                            setPageIndex(newPage);
                        }}
                        pageSize={20}
                    />
                </div>
                <div className="statistic-widgets">
                    <ReferralWidget
                        lastPercent={10}
                        referralTotal={total?.totalReferralsBonus}
                        total={total?.totalInvested}
                        agio={total?.totalAgio}
                        totalBonus={total?.totalInvested}
                    />
                    <BonusWidget data={total?.totalInvestedByMonth} type={BonusWidgetEnum.INVEST} />

                    <BonusWidget data={total?.totalPayoutsByMonth} type={BonusWidgetEnum.PAYOUT} />
                    <BonusWidget data={total?.totalActivePayoutByMonth} type={BonusWidgetEnum.PAYOUT} title={'Total active'} />
                    <BonusWidget data={total?.totalBonusPayoutByMonth} type={BonusWidgetEnum.PAYOUT} title={'Total bonus'} />
                    <PeopleWidget
                        totalRegisteredByMonth={total?.totalRegisteredByMonth}
                        code={countryCode}
                        handleCode={setCountryCode}
                        year={widgetYear}
                        handleYear={setWidgetYear}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReferralStatistic;
