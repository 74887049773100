import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SeifLogo } from '../../assets/images';
import BaseButton from '../common/base-button';
import BaseInput from '../common/base-input';
import LoadingIndicator from '../loading-indicator';
import { useFormRules } from '../../hooks/use-form-rules';
import { BaseFormType, RouteModalPropsType } from '../../models/models';
import { ReferralFormType } from '../../pages/client/refferals/refferals';

type PropsType = BaseFormType<ReferralFormType> &
    RouteModalPropsType & {
        loading: boolean;
        handleSend: () => void;
    };

const RefferalSendModal: React.FC<PropsType> = ({ isOpened, onClose, loading, handleSend, register, errors, trigger }) => {
    const { t } = useTranslation();
    const { requiredTrimed, formRules } = useFormRules();

    return (
        <>
            <LoadingIndicator visible={loading} />
            <Modal show={isOpened} size="sm" onHide={onClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <div>
                            <BaseInput
                                {...register('email', { ...requiredTrimed, ...formRules.email })}
                                errors={errors}
                                type="text"
                                label={t('auth.email')}
                                trigger={trigger}
                            />

                            <BaseButton onClick={handleSend} className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.send')}
                            </BaseButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RefferalSendModal;
