//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseInput from '../../../../components/common/base-input';
import { useFormRules } from '../../../../hooks/use-form-rules';
import { formatCurrency } from '../../../../utils/utils';
import { Modal } from 'react-bootstrap';
import { BankLogo, SeifLogo } from '../../../../assets/images/index';
import BaseButton from './../../../../components/common/base-button';
import LoadingIndicator from './../../../../components/loading-indicator';

type PropsType = {
    isOpened: boolean;
    paymentPortfolios: ClientStatisticPortfolioType[];
    onClose: () => void;
    handleSend: () => void;
};

const ReinvestModal: React.FC<PropsType> = ({
    paymentPortfolios,
    isOpened,
    onClose,
    loading,
    handleSend,
    register,
    errors,
    trigger,
    watch,
    setValue,
    unregister,
}) => {
    const { t } = useTranslation();
    const { requiredTrimed, formRules } = useFormRules();
    const [canReplace, setCanReplace] = useState<boolean>(true);
    const ibanHold: string = (watch && watch(`ibanHold`)) ?? '';
    const [withdrawalAmount, setWithdrawalAmount] = useState<number>(0);
    const [amount, setTotalAvailableAmount] = useState<number>(0);

    useEffect(() => {
        const sum = paymentPortfolios.reduce((total, current) => (total = total + current.currentAmount), 0);
        setTotalAvailableAmount(parseFloat(sum).toFixed(2));
        if (!isOpened) {
            unregister(['ibanHold']);
        }
        if (ibanHold) {
            const preview = ibanHold
                .toUpperCase()
                .replace(/\W/gi, '')
                .replace(/(.{4})/g, '$1 ');

            canReplace && setValue && setValue(`ibanHold`, preview);
        }
    }, [ibanHold, setValue, canReplace, paymentPortfolios, isOpened, unregister]);

    return (
        <>
            <LoadingIndicator visible={loading} />
            <Modal show={isOpened} onHide={onClose} centered size="sm">
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <div>
                            {amount && <div className="text-muted">Maximum withdrawal amount {amount}€</div>}
                            <span className="reinvest-input-title">How much do you want to withdraw?</span>
                            <BaseInput
                                {...register('amountWithdraw', { ...formRules.number, ...formRules.max(amount) })}
                                label={t('invest.reinvestTitle')}
                                trigger={trigger}
                                errors={errors}
                                className="umud pr-2"
                                onChange={(e) => setWithdrawalAmount(e.target.value)}
                            />
                            <span className="reinvest-input-title">Amount that will be reinvested.</span>
                            <BaseInput
                                trigger={trigger}
                                errors={errors}
                                className="umud pr-2"
                                value={parseFloat(amount - withdrawalAmount).toFixed(2)}
                                {...register('amountReinvest', { ...formRules.number, ...formRules.min(0) })}
                                disabled={false}
                            />
                            <BaseInput
                                {...register('ibanHold', { ...formRules.required, ...formRules.iban })}
                                errors={errors}
                                icon={BankLogo}
                                type="text"
                                label={t('withdrawal.iban')}
                                trigger={trigger}
                                onKeyDown={(e) => {
                                    if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === 'Delete') {
                                        setCanReplace(false);
                                    } else {
                                        setCanReplace(true);
                                    }
                                }}
                            />

                            <BaseButton onClick={handleSend} className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.send')}
                            </BaseButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReinvestModal;
