import React from 'react';
import { formatCurrency, formatPercent } from './../utils/utils';

type PropsType = {
    percent?: number;
    value?: number;
    color: string;
    hover?: boolean;
};

const CircleChart: React.FC<PropsType> = ({ percent = 100, value, color, hover }) => {
    return (
        <>
            <div className="circular-wrapper">
                <svg viewBox="0 0 36 36" className="circular-chart">
                    <path
                        className="circle"
                        stroke={color}
                        strokeDasharray={`${percent}, 100`}
                        d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                </svg>
                <div className="cirlce-content">
                    <div className="content-border">
                        <div className="text-content">
                            {hover ? (
                                <>
                                    <span className="percent">{formatPercent(percent)}</span>
                                    <span className="amount-eur">{formatCurrency(value)}</span>
                                </>
                            ) : (
                                <>{value !== undefined ? formatCurrency(value) : formatPercent(percent)}</>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CircleChart;
