import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../utils/utils';

type PropsType = {
    data: {
        [key: string]: number;
    };
};

const WithdrawalWidget: React.FC<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const activeMonth = new Date().getMonth() + 1;

    const totalWithdrawal = useMemo(() => {
        return Object.values(data).reduce((val, acc) => {
            return (acc += val);
        }, 0);
    }, [data]);
    return (
        <div className="profit-container">
            <div className="profit-month">{moment(activeMonth, 'M').format('MMMM')}</div>
            <div className="profit-amount">{formatCurrency(data[activeMonth])}</div>
            <div className="profit-text">{`Total Withdrawal: ${formatCurrency(totalWithdrawal)}`}</div>

            {Object.entries(data)
                .filter(([key]) => +key !== activeMonth)
                .reverse()
                .map(([month, value]) => (
                    <div className="profit-count" key={month}>
                        <div className="percentage">{moment(+month, 'M').format('MMMM')}</div>
                        <div className="people">{formatCurrency(value)}</div>
                    </div>
                ))}
        </div>
    );
};

export default WithdrawalWidget;
