import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import BaseInput from '../../../components/common/base-input';
import LoadingIndicator from '../../../components/loading-indicator';
import { CloseTradeHistoryResponseType, CloseTradeHistoryType, CloseTradeRequestType } from '../../../models/api/dashboard-model';
import { BalikEnum } from '../../../models/enums';
import adminService from '../../../service/adminService';
import { useFormRules } from './../../../hooks/use-form-rules';
import { formatPercent } from './../../../utils/utils';

const Trades: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const { addToast } = useToasts();
    const { t } = useTranslation();
    const { formRules } = useFormRules();

    const [tradeList, setTradeList] = useState<CloseTradeHistoryType>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadTradeHistory();
    }, [errors]);

    const loadTradeHistory = () => {
        adminService.tradeHistroy().then(({ data }: AxiosResponse<CloseTradeHistoryResponseType>) => {
            setTradeList(data.history);
        });
    };

    const closeTrade = (data: CloseTradeRequestType) => {
        setLoading(true);
        const requestData = Object.fromEntries(
            Object.entries(data).map(([key, item]) => {
                return [key, +item / 100];
            }),
        );
        adminService
            .closeTrade(requestData)
            .then(onSuccess)
            .finally(() => setLoading(false));
        function onSuccess() {
            addToast(t('toast.tradeClosed'));
            reset();
            loadTradeHistory();
        }
    };

    const formatPercentValue = (value: number): string => {
        return formatPercent(value * 100);
    };

    return (
        <>
            <LoadingIndicator visible={loading} />
            <div className="trades-container">
                <form onSubmit={handleSubmit(closeTrade)} className="row row-cols-lg-auto g-3 align-items-center trade-form">
                    <div className="">
                        <BaseInput
                            {...register(BalikEnum.ACTIVE, { ...formRules.number, ...formRules.max(100) })}
                            label={`Active %`}
                            trigger={trigger}
                            errors={errors}
                        />
                    </div>
                    <div className="">
                        <BaseInput
                            {...register(BalikEnum.ESTABLISHED, { ...formRules.number, ...formRules.max(100) })}
                            label={`Established %`}
                            trigger={trigger}
                            errors={errors}
                        />
                    </div>
                    <div className="">
                        <BaseInput
                            {...register(BalikEnum.STARTUP, { ...formRules.number, ...formRules.max(100) })}
                            label={`Startup %`}
                            trigger={trigger}
                            errors={errors}
                        />
                    </div>

                    <div className="">
                        <button className="base-btn " disabled={loading}>
                            {t('common.update')}
                        </button>
                    </div>
                </form>

                <div className="trade-history">
                    {tradeList &&
                        Object.entries(tradeList)
                            .sort()
                            .reverse()
                            .map(([key, item], index) => {
                                return (
                                    <div className="trade-item" key={index}>
                                        <div className="item-text">{formatPercentValue(item.ACTIVE)}</div>
                                        <div className="item-text">{formatPercentValue(item.ESTABLISHED)}</div>
                                        <div className="item-text">{formatPercentValue(item.STARTUP)}</div>
                                        <div className="item-text">{moment(key).format('DD-MM-yyyy')}</div>
                                    </div>
                                );
                            })}
                </div>
            </div>
        </>
    );
};

export default Trades;
