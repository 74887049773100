import { useLocation, useRouteMatch } from 'react-router-dom';

type UsePrepareLink = {
    to?: string;
    isRelativePath?: boolean;
    query?: Record<string, string>;
    pushToQuery?: Record<string, string>;
    hash?: string;
    keepOldQuery?: boolean;
    state?: Record<string, string>;
};

export default ({ to, isRelativePath = false, query = {}, pushToQuery = {}, hash, keepOldQuery = false, state = {} }: UsePrepareLink) => {
    const location = useLocation();
    const match = useRouteMatch();

    let pathname;

    if (match && isRelativePath) {
        pathname = match.url + to;
    } else {
        pathname = to || location.pathname;
    }

    const newQuery = keepOldQuery ? new URLSearchParams(location.search) : new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
        newQuery.set(key, value as string);
    });

    Object.entries(pushToQuery).forEach(([key, value]) => {
        const currentValue = newQuery.get(key);
        const splittedValue = currentValue ? currentValue.split(',') : [];
        splittedValue.push(value as string);

        newQuery.set(key, splittedValue.toString());
    });

    return {
        pathname: pathname.replace(/\/\//g, '/'),
        search: newQuery.toString() ? `?${newQuery.toString()}` : '',
        hash,
        state,
    };
};
