import React from 'react';
import { Carousel, Col, Dropdown, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import homeBg from '../../../assets/video/home-bg.mp4';
import { LangEnum } from '../../../models/enums';
import SocialLinks from './../components/social-links';

const SectionHome: React.FC = () => {
    const { i18n, t } = useTranslation();
    const changeLanguage = (lang: LangEnum) => {
        localStorage.setItem('i18n.locale', lang);
        i18n.changeLanguage(lang);
    };

    return (
        <div className="home-container">
            <Row className="m-0 w-100">
                <div className="col-1 d-flex justify-content-center">
                    <div className="landing-counter-wrapper mobile-hide">
                        <div className="counter-title last">{`01`}</div>
                        <div className="divider" />
                        <div className="counter-title ">{`05`}</div>
                    </div>
                </div>
                <Col className="landing-grid-wrapper">
                    <div className="section-container">
                        <video width="320" height="240" autoPlay loop muted id="home-bg">
                            <source src={homeBg} type="video/mp4" />
                        </video>
                        <div className="section-home">
                            <Carousel controls={false}>
                                <Carousel.Item>
                                    <span className="second-label">
                                        <Trans i18nKey="landing.home.blockchain" />
                                        <p>
                                            <span>
                                                <Trans i18nKey="landing.home.education" />
                                            </span>
                                            <Trans i18nKey="landing.home.platform" />
                                        </p>
                                    </span>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <span className="second-label learn-earn">
                                        <Trans i18nKey="landing.home.learn" />
                                        <span className="mobile-text">
                                            <Trans i18nKey="landing.home.with" />
                                        </span>
                                        <Trans i18nKey="landing.home.SEIF" />
                                    </span>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </Col>
                <div className="col-1 d-flex justify-content-center">
                    <SocialLinks />
                </div>
            </Row>
            <div className="lang-dropdown">
                <Row className="m-0">
                    <Col xs={1}></Col>

                    <Col>
                        <div className="my-5 ">
                            <div className="language-btn">
                                <Dropdown drop={'up'} className="search-typehead">
                                    <Dropdown.Toggle id="dropdown-basic" className="btn dropdown-toggle language-bg">
                                        <Trans i18nKey={`landing.${i18n.language}`} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="mb-1 w-100">
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.EN)}>
                                            <Trans i18nKey="landing.en" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.SK)}>
                                            <Trans i18nKey="landing.sk" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.CZ)}>
                                            <Trans i18nKey="landing.cz" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.PL)}>
                                            <Trans i18nKey="landing.pl" />
                                        </Dropdown.Item>
                                        {/* TO DO GREEK LANGUAGE */}
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.GR)}>
                                            <Trans i18nKey="landing.gr" />
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SectionHome;
