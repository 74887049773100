import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TelegramIcon } from '../../../assets/images';

const SocialLinks: React.FC = () => {
    return (
        <>
            <div className="social-container mr-2 mobile-hide">
                <div className="social-divider"></div>
                <div className="icon-wrapper">
                    <a href="https://www.youtube.com/channel/UCFRCKAe2ohrnQHPAD2QasGA/videos" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutube} color="white" size="2x" className="social-icon" />
                    </a>
                    <a href="https://t.me/seifgroupchat" target="_blank" rel="noopener noreferrer">
                        <img src={TelegramIcon} alt="" className="social-icon" />
                    </a>
                </div>
                <div className="social-divider"></div>
            </div>
        </>
    );
};

export default SocialLinks;
