import React, { useContext, useState } from 'react';
import { useLocalStorage } from '../hooks/use-local-storage';
import { UserProfileType } from '../models/api/auth-models';
import { Children } from '../models/models';
import clientService from '../service/clientService';

type UserProviderPropsType = {
    // data: UserProfileType;
    children: Children;
};

const UserContextDefaultValue = {
    user: undefined as UserProfileType | undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser: (_user: UserProfileType | undefined) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadUser: () => {},
};

const UserContext = React.createContext(UserContextDefaultValue);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider: React.FC<UserProviderPropsType> = ({ children }) => {
    const [user, setUser] = useState<UserProfileType>();
    const { clearStore } = useLocalStorage<string>('', '');

    const loadUser = () => {
        clientService.getUser().then(
            (response) => {
                setUser(response.data.user);
            },
            () => {
                clearStore();
            },
        );
    };

    return <UserContext.Provider value={{ user, setUser, loadUser }}>{children}</UserContext.Provider>;
};
