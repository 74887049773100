import moment from 'moment';
import React, { useContext } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserProfileType } from '../../../models/api/auth-models';
import { EmployeeStatisticResponseType, EmpStatItemType } from '../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../utils/utils';
import UserLogo from './../../../components/user-logo';

type PropsType = {
    eventKey: string;
    item: UserProfileType;
    month: number;
    onMonthChange: (id: number, month: number) => void;
    employeeStatistics?: EmployeeStatisticResponseType;
    loadStatistics: (id: number) => void;
};

const EmployeeItem: React.FC<PropsType> = ({ eventKey, item, month, onMonthChange, employeeStatistics, loadStatistics }) => {
    const { t } = useTranslation();

    const getTotalSum = (data: EmpStatItemType[]) => {
        return data.reduce((val, acc) => {
            return (val += acc.amount);
        }, 0);
    };

    return (
        <div className="affiliate-item">
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text">{`${moment(new Date().getMonth() + 1, 'M').format('MMMM')} - ${formatCurrency(
                            item.totalMonthInvestment,
                        )}`}</div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.email}</div>
                    <div className="secondary-text">{formatCurrency(item.totalMonthWithdrawal)}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.phone}</div>
                    <div className="secondary-text">&nbsp;</div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action">
                    <ContextAwareToggle eventKey={eventKey} callback={() => loadStatistics(item.id)} />
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <div className="item-portfolio-container">
                    {employeeStatistics ? (
                        <>
                            <div className="item-info-container item-five-in-row">
                                <div className="affilate-amount justify-content-start align-items-center">
                                    <div className="secondary-text align-items-center d-flex">
                                        <span className="month-arrow" onClick={() => onMonthChange(item.id, month - 1)}>
                                            {'<'}
                                        </span>
                                        <span> {moment((month % 12) + 1, 'M').format('MMMM')}</span>
                                        <span className="month-arrow" onClick={() => onMonthChange(item.id, month + 1)}>
                                            {'>'}
                                        </span>
                                    </div>
                                    <div className="primary-text">{formatCurrency(employeeStatistics.totalInvestedByMonth)}</div>
                                    <div className="secondary-text align-items-start d-flex">Total</div>
                                    <div className="primary-text">{formatCurrency(employeeStatistics.totalInvested)}</div>
                                </div>
                                <div className="affilate-amount justify-content-start">
                                    <div className="secondary-text">Invest</div>
                                    {employeeStatistics.investmentsByMonth.map((item) => (
                                        <>
                                            <div className="secondary-text">{item.name}</div>
                                            <div className="primary-text">{formatCurrency(item.amount)}</div>
                                            <div className="secondary-text">{t(`enums.investType.${item.type}`)}</div>
                                            <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                        </>
                                    ))}
                                    <>
                                        <div className="secondary-text">Total</div>
                                        <div className="primary-text">{formatCurrency(getTotalSum(employeeStatistics.investmentsByMonth))}</div>
                                    </>
                                </div>
                                <div className="affilate-amount justify-content-start">
                                    <div className="secondary-text">Total Invest</div>
                                    {employeeStatistics.totalInvestedByClients.map((item) => (
                                        <>
                                            <div className="secondary-text">{item.name}</div>
                                            <div className="primary-text">{formatCurrency(item.amount)}</div>
                                            <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                        </>
                                    ))}
                                    <>
                                        <div className="secondary-text">Total</div>
                                        <div className="primary-text">{formatCurrency(getTotalSum(employeeStatistics.totalInvestedByClients))}</div>
                                    </>
                                </div>
                                <div className="affilate-amount justify-content-start">
                                    <div className="secondary-text">Withdrawal</div>
                                    {employeeStatistics.forceWithdrawalsByMonth.map((item) => (
                                        <>
                                            <div className="secondary-text">{item.name}</div>
                                            <div className="primary-text">{formatCurrency(item.amount)}</div>
                                            <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                        </>
                                    ))}
                                    <>
                                        <div className="secondary-text">Total</div>
                                        <div className="primary-text">{formatCurrency(getTotalSum(employeeStatistics.forceWithdrawalsByMonth))}</div>
                                    </>
                                    {/* <div className="secondary-text align-items-center">{`${user.name} ${user.surname}`}</div> */}
                                </div>
                                <div className="affilate-amount justify-content-start">
                                    <div className="secondary-text">Withdrawal Actrive t.</div>
                                    {employeeStatistics.activeWithdrawalsByMonth.map((item) => (
                                        <>
                                            <div className="secondary-text">{item.name}</div>
                                            <div className="primary-text">{formatCurrency(item.amount)}</div>
                                            <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                        </>
                                    ))}
                                    <>
                                        <div className="secondary-text">Total</div>
                                        <div className="primary-text">{formatCurrency(getTotalSum(employeeStatistics.activeWithdrawalsByMonth))}</div>
                                    </>
                                    {/* <div className="secondary-text align-items-center">{`${user.name} ${user.surname}`}</div> */}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="portfolio-empty">
                            <span>{t('affilate.empty-list')}</span>
                        </div>
                    )}
                </div>
            </Accordion.Collapse>
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show'}
        </button>
    );
}

export default EmployeeItem;
