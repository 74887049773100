import React from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarLinkItem } from '../../models/models';

type PropsType = {
    item: SidebarLinkItem;
};

const NavLinkItem: React.FC<PropsType> = ({ item }) => {
    return (
        <li className="side-nav-item">
            {!!item.count && item.count !== 0 && (
                <div className="side-item-badge">
                    <span>{item.count}</span>
                </div>
            )}
            <NavLink to={item.to} exact activeClassName="active" className="nav-link">
                {item.title}
            </NavLink>
        </li>
    );
};

export default NavLinkItem;
