import { AxiosResponse } from 'axios';
import { ForgotPasswordType, LoginDataType, LoginResponseType, RegistrationDataType, ResetDataType } from '../models/api/auth-models';
import axiosInstance from './axiosConfig';

const authService = {
    login: (data: LoginDataType): Promise<AxiosResponse<LoginResponseType>> => {
        return axiosInstance.post<LoginResponseType>('/login', data);
    },
    register: (data: RegistrationDataType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/register', data);
    },
    registerWithReferal: (data: RegistrationDataType, token: string): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post(`/register/referral?token=${token}`, data);
    },
    registerEmployee: (data: RegistrationDataType, token: string): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post(`/register/employee?token=${token}`, data);
    },
    registerClient: (data: RegistrationDataType, token: string): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post(`/register/client?token=${token}`, data);
    },
    registerConfirm: (token: string): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post(`/register/confirm?token=${token}`);
    },
    forgotPassword: (data: ForgotPasswordType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/forgot-password', data);
    },
    resetPassword: (data: ResetDataType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/reset-password', data);
    },
};

export default authService;
