//@ts-nocheck
import React, { useState } from 'react';
import { CloseIcon, RightArrowIcon, SeifLogo } from '../../../assets/images';
import BaseButton from '../../../components/common/base-button';
import BaseInput from '../../../components/common/base-input';
import { CountryType, RegistrationDataType } from '../../../models/api/auth-models';
import { BaseFormType } from '../../../models/models';
import { useFormRules } from '../../../hooks/use-form-rules';
import { Modal, Form, InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import LoadingIndicator from '../../../components/loading-indicator';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../components/datePicker/datePicker';
import BaseCheckbox from './../../../components/common/base-checkbox';
import PasswordInput from './../../../components/common/password-input';
import { Controller } from 'react-hook-form';
import { countriesList } from '../../../utils/utils';
import ReactCountryFlag from 'react-country-flag';

type PropsType = BaseFormType<RegistrationDataType> & {
    countries: CountryType[];
    handleRegistration: () => void;
    handleClose: () => void;
    loading: boolean;
    isOpened: boolean;
    code: string;
    handleCode: (code: string) => void;
};

type CountryToggle = {
    onClick: (e: React.MouseEvent) => void;
};

const CountryToggle: React.FC<CountryToggle> = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        className="month-toggle"
        role="button"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children} {'  '}
    </span>
));

const RegistrationView: React.FC<PropsType> = ({
    countries,
    handleRegistration,
    register,
    errors,
    trigger,
    setValue,
    getValues,
    code,
    handleCode,
    control,
    watch,
    loading,
    isOpened,
    handleClose,
}) => {
    const { requiredTrimed, formRules, phoneRules, equals } = useFormRules();
    const { t } = useTranslation();

    const password = (watch && watch('password')) ?? '';
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    return (
        <>
            <LoadingIndicator visible={loading} />
            <Modal show={isOpened} size="sm" onHide={handleClose} className="p-0 m-0" centered fullscreen="sm-down" backdrop="static">
                <Modal.Body className="auth-modal auth-container">
                    <div className="auth-container auth-register-padding register-container">
                        <div className="auth-logo register-logo">
                            <img src={SeifLogo} alt="" />
                        </div>

                        <div className="modal-close-btn" onClick={handleClose}>
                            <img src={CloseIcon} alt="close" />
                        </div>

                        <form onSubmit={handleRegistration}>
                            <BaseInput
                                {...register('name', { ...requiredTrimed })}
                                errors={errors}
                                trigger={trigger}
                                type="text"
                                label={t('auth.firstname')}
                            />

                            <BaseInput
                                {...register('surname', { ...requiredTrimed })}
                                errors={errors}
                                trigger={trigger}
                                type="text"
                                label={t('auth.lastname')}
                            />
                            <BaseInput
                                {...register('username', { ...requiredTrimed })}
                                errors={errors}
                                trigger={trigger}
                                type="text"
                                label={t('auth.username')}
                            />
                            <BaseInput
                                {...register('email', { ...requiredTrimed, ...formRules.email })}
                                errors={errors}
                                trigger={trigger}
                                type="text"
                                label={t('auth.email')}
                            />

                            <Controller
                                rules={{
                                    ...requiredTrimed,
                                    ...phoneRules,
                                }}
                                render={({ field }) => (
                                    <Form.Group className="country-form mt-4">
                                        <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                                            {t('auth.phoneNumber')}
                                        </Form.Label>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text>
                                                <Dropdown onSelect={(eventKey: any) => handleCode(eventKey as number)} className="income-dropdown">
                                                    <Dropdown.Toggle as={CountryToggle} id="dropdown-custom-components">
                                                        {code}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="phone-menu">
                                                        {countriesList.map((item) => (
                                                            <Dropdown.Item key={item.code} eventKey={item.phone}>
                                                                <ReactCountryFlag
                                                                    style={{
                                                                        fontSize: '2em',
                                                                    }}
                                                                    countryCode={item.code.toUpperCase()}
                                                                />
                                                                <span>{`${item.phone} ${item.country}`}</span>
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </InputGroup.Text>
                                            <FormControl id="inlineFormInputGroup" type="text" {...field} placeholder={t('auth.phoneNumber')} />
                                        </InputGroup>
                                        {errors?.phone && <span className="label-error">{errors.phone.message}</span>}
                                    </Form.Group>
                                )}
                                control={control}
                                name="phone"
                            />

                            <Controller
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <Form.Group className="country-form mt-4">
                                        <Form.Label className="country-label">Country</Form.Label>
                                        <Form.Control as="select" size="sm" {...field} className="countrySelect">
                                            {countries?.map((item) => (
                                                <option key={item.id} value={item.code}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                )}
                                control={control}
                                name="countryCode"
                            />

                            <DatePicker setValue={setValue} maxDate={new Date()} name="birthday" {...register('birthday', { ...requiredTrimed })}>
                                <BaseInput errors={errors} trigger={trigger} type="text" maxLength={10} label={t('auth.birthdate')} />
                            </DatePicker>
                            <PasswordInput
                                {...register('password', { ...formRules.minLengthX(6) })}
                                errors={errors}
                                trigger={trigger}
                                paswordVisible={showPassword}
                                handlePasswordVisibility={() => setShowPassword(!showPassword)}
                                label={t('auth.password')}
                                placeholder="Password"
                            />

                            <PasswordInput
                                {...register('passwordConfirm', { ...equals(password, 'password') })}
                                errors={errors}
                                trigger={trigger}
                                paswordVisible={showConfirmPassword}
                                handlePasswordVisibility={() => setShowConfirmPassword(!showConfirmPassword)}
                                label={t('auth.passwordConfirm')}
                            />

                            <BaseCheckbox
                                id="terms"
                                {...register('terms', { validate: (value) => !!value })}
                                errors={errors}
                                label={
                                    <>
                                        I agree to the {'\u00A0'}
                                        <a href="/docs/terms.pdf" className="termsLink" target="_blank">
                                            Terms & Conditions
                                        </a>
                                        {'\u00A0\u00A0'}and{'\u00A0\u00A0'}
                                        <a href="/docs/CWPOPD.pdf" className="termsLink" target="_blank">
                                            GDPR
                                        </a>
                                        <br />
                                        Your contract is availible here{'\u00A0'}
                                        <img src={RightArrowIcon} />
                                        {'\u00A0'}
                                        <a href="/docs/Agreement_on_Administration_of_Cryptocurrencies.pdf" className="termsLink" target="_blank">
                                            EN
                                        </a>
                                    </>
                                }
                            />

                            <BaseButton type="submit" className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.singup')}
                            </BaseButton>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RegistrationView;
