//@ts-nocheck
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { CountryType } from '../../../../models/api/auth-models';
import { ProfitsResponseType } from '../../../../models/api/dashboard-model';
import clientService from '../../../../service/clientService';
import { ArrowDown, SlovakFlag } from '../../../../assets/images';

type PropsType = Pick<ProfitsResponseType, 'totalRegisteredByMonth'> & {
    code: string;
    handleCode: (code: string) => void;
    year: number;
    handleYear: (year: number) => void;
};

type ToggleType = {
    onClick: (e: React.MouseEvent) => void;
};

const FlagToggle: React.FC<ToggleType> = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        className="month-toggle"
        role="button"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children} {'  '}
    </span>
));

const YearToggle: React.FC<ToggleType> = ({ children, onClick }) => (
    <span
        className="month-toggle"
        role="button"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children} {'  '}
        <img src={ArrowDown} alt="" className="income-icon" />
    </span>
);

const yearList = [2021, 2022, 2023];

const month1 = ['1', '2', '3', '4'];
const month2 = ['5', '6', '7', '8'];
const month3 = ['9', '10', '11', '12'];

const PeopleWidget: React.FC<PropsType> = ({ totalRegisteredByMonth, code, handleCode, year, handleYear }) => {
    const { t } = useTranslation();

    const [countries, setCountries] = useState<CountryType[]>([]);

    useEffect(() => {
        clientService.getCountries().then((res) => {
            setCountries(res.data.countries);
        });
    }, []);

    const maxCount = useMemo(() => {
        if (!totalRegisteredByMonth) return 0;
        return Math.max(...Object.values(totalRegisteredByMonth));
    }, [totalRegisteredByMonth]);

    const getMonthName = useCallback((month: number) => {
        return moment(`${month}`, 'M').format('MMM');
    }, []);

    const getBlockCount = useCallback(
        (value: number) => {
            const count = Math.ceil((value / maxCount) * 6);
            return new Array(count).fill('');
        },
        [maxCount],
    );

    return (
        <div className="refferal refferal-container">
            <div className="refferal-info">
                <div className="people-header-wrapper">
                    <div className="info-text">New people statistics</div>
                    <div>
                        <Dropdown drop="start" onSelect={(eventKey: any) => handleCode(eventKey)}>
                            <Dropdown.Toggle as={FlagToggle} id="dropdown-custom-components">
                                <ReactCountryFlag
                                    style={{
                                        fontSize: '2em',
                                    }}
                                    countryCode={code}
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="flag-menu">
                                {countries.map((item) => (
                                    <Dropdown.Item key={item.id} eventKey={item.code}>
                                        <ReactCountryFlag
                                            style={{
                                                fontSize: '2em',
                                            }}
                                            countryCode={item.code.toUpperCase()}
                                        />
                                        <span>{item.name}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <img src={SlovakFlag} alt="" /> */}
                    </div>
                </div>
                <div className="mt-2">
                    <Dropdown onSelect={(eventKey: any) => handleYear(eventKey as number)} className="income-dropdown">
                        <Dropdown.Toggle as={YearToggle} id="dropdown-custom-components">
                            Year: {year}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {yearList.map((year) => (
                                <Dropdown.Item key={year} eventKey={year}>
                                    {year}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {totalRegisteredByMonth && (
                <>
                    <div className="country-chart-wrapper">
                        {month1.map((month, index) => (
                            <div className="vertical-item" key={month}>
                                <div className="chart-month">{totalRegisteredByMonth[month] || 0}</div>
                                {getBlockCount(totalRegisteredByMonth[month] || 0).map((i, index) => (
                                    <div key={index} className="chart-item"></div>
                                ))}
                                <div className="chart-month">{getMonthName(month)}</div>
                            </div>
                        ))}
                    </div>
                    <div className="country-chart-wrapper">
                        {month2.map((month, index) => (
                            <div className="vertical-item" key={month}>
                                <div className="chart-month">{totalRegisteredByMonth[month] || 0}</div>
                                {getBlockCount(totalRegisteredByMonth[month] || 0).map((i, index) => (
                                    <div key={index} className="chart-item"></div>
                                ))}
                                <div className="chart-month">{getMonthName(month)}</div>
                            </div>
                        ))}
                    </div>
                    <div className="country-chart-wrapper">
                        {month3.map((month, index) => (
                            <div className="vertical-item" key={month}>
                                <div className="chart-month">{totalRegisteredByMonth[month] || 0}</div>
                                {getBlockCount(totalRegisteredByMonth[month] || 0).map((i, index) => (
                                    <div key={index} className="chart-item"></div>
                                ))}
                                <div className="chart-month">{getMonthName(month)}</div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
export default PeopleWidget;
