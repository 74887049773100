import React from 'react';
import { InvestPhilosopy, StartUp, EstabLishedImage, ActiveImage } from '../../../assets/images';
import StarfieldAnimation from 'react-starfield-animation';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

const Products: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id={'products'} className="products-bg">
                <StarfieldAnimation
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                />
                <Container>
                    <Row className="justify-content-md-center spacing-up">
                        <Col>
                            <Fade left>
                                <h1 className="products-hold">
                                    <Trans i18nKey={'landing.products.hold'} />
                                </h1>
                                <p className="products-hold-desc">
                                    <Trans i18nKey={'landing.products.longTermInvest'} />
                                </p>
                            </Fade>
                        </Col>
                        <Col md="auto">
                            <Fade left>
                                <img className="products-euro" src={InvestPhilosopy} alt="InvestPhilosopy" />
                            </Fade>
                        </Col>
                        <Col>
                            <Fade right>
                                <h1 className="products-trade">
                                    <Trans i18nKey={'landing.products.trade'} />
                                </h1>
                                <p className="products-trade-desc">
                                    <Trans i18nKey={'landing.products.activeTrading'} />
                                </p>
                            </Fade>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <h5 className="posibility-withdraw">
                                <Trans i18nKey={'landing.products.withdrawalPosibility'} />
                            </h5>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center spacing-down">
                        <Col className="products-mobile-center">
                            <Fade left>
                                <h1 className="products-startup">
                                    <Trans i18nKey={'landing.products.startup'} />
                                </h1>
                                <img src={StartUp} alt="StartUp" className="products-img" />
                                <div className="pb-1"></div>
                                <p className="startup-desc">
                                    <Trans i18nKey={'landing.products.startupDesc'} />
                                </p>
                            </Fade>
                        </Col>
                        <Col className="products-mobile-center">
                            <Fade left>
                                <h1 className="products-established">
                                    <Trans i18nKey={'landing.products.established'} />
                                </h1>
                                <img src={EstabLishedImage} alt="EstabLishedImage" className="products-img" />
                                <div className="pb-2"></div>
                                <p className="established-desc">
                                    <Trans i18nKey={'landing.products.establishedDesc'} />
                                </p>
                            </Fade>
                        </Col>
                        <Col className="products-mobile-center">
                            <Fade left>
                                <h1 className="products-active">
                                    <Trans i18nKey={'landing.products.active'} />
                                </h1>
                                <img src={ActiveImage} alt="ActiveImage" className="products-img" />
                                <div className="pb-2"></div>
                                <p className="active-desc">
                                    <Trans i18nKey={'landing.products.activeDesc'} />
                                </p>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Products;
