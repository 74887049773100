import React, { useContext } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { ClientProfileType, DeleteNoteRequest } from '../../../models/api/dashboard-model';
import { generateShortUserName } from '../../../utils/utils';
import UserLogo from './../../../components/user-logo';
import { useTranslation } from 'react-i18next';

type PropsType = {
    eventKey: string;
    item: ClientProfileType;
    handleDeactivateClient: (id: number) => void;
    handleDeleteNote: (data: DeleteNoteRequest) => void;
};

const AdminCLientsItem: React.FC<PropsType> = ({ eventKey, item, handleDeactivateClient, handleDeleteNote }) => {
    const { t } = useTranslation();
    return (
        <div className="affiliate-item">
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text"></div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.email}</div>
                    <div className="secondary-text"></div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.phone}</div>
                    <div className="secondary-text"></div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action d-flex flex-column gap-1">
                    <ContextAwareToggle eventKey={eventKey} />
                    {item.isEnabled && (
                        <button className="base-btn" onClick={() => handleDeactivateClient(item.id)}>
                            {t('common.deactivate')}
                        </button>
                    )}
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <div className="">
                    <div className="note-list-wrapper">
                        {item.employeeNotes?.map((i, index) => (
                            <div key={index} className="d-flex">
                                <div className="note-item" dangerouslySetInnerHTML={{ __html: i }}></div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button className="base-btn" onClick={() => handleDeleteNote({ index, userId: item.id })}>
                                        {t('common.delete')}
                                    </button>
                                </div>
                            </div>
                        ))}
                        {item.employeeNotes?.length === 0 && (
                            <div className="portfolio-empty">
                                <span>{t('affilate.empty-list')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </Accordion.Collapse>
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show'}
        </button>
    );
}

export default AdminCLientsItem;
