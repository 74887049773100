import React, { useEffect, useState } from 'react';
import ProfileView from './profile-view';
import clientService from './../../../service/clientService';
import { AxiosResponse } from 'axios';
import { ProfileResponseType, ProfileType } from '../../../models/api/auth-models';

const Profile: React.FC = () => {
    const [data, setData] = useState<ProfileType>();

    const loadProfile = () => {
        clientService.getProfile().then(({ data }: AxiosResponse<ProfileResponseType>) => {
            setData(data.identityDataDTO);
        });
    };
    useEffect(() => {
        loadProfile();
    }, []);

    return (
        <>
            <ProfileView data={data} updateData={loadProfile} />
        </>
    );
};
export default Profile;
