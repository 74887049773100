import React from 'react';
import { Col } from 'react-bootstrap';
import { TopAffiliatesType } from '../../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../../utils/utils';

type PropsType = {
    topAffiliates: TopAffiliatesType[];
};

const TopPerformance: React.FC<PropsType> = ({ topAffiliates }) => {
    return (
        <div className="performance-container">
            <div className="performance-title">Top performance</div>
            <div className="performance-list">
                {topAffiliates.map((item) => (
                    <div key={item.id} className="performance-item">
                        <Col className="user-profile">
                            <div className="user-img">
                                <span className="user-txt">{generateShortUserName(item.fullName)}</span>
                            </div>
                            <div className="user-info">
                                <div className="primary-text">{item.fullName}</div>
                                <div className="secondary-text">{item.members} members</div>
                            </div>
                        </Col>
                        <Col className="performance-info">
                            <div className="primary-text">{formatCurrency(item.bonuses)}</div>
                            <div className="secondary-text">Paid to you</div>
                        </Col>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TopPerformance;
