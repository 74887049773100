import React from 'react';
import {
    IndividualImage,
    QualityImage,
    SecurityImage,
    NewsImage,
    InvestImage,
    EducationImage,
    BenefitsImage,
    MainHashRoadmap,
    BtcFondRoadmap,
    SeifGroupRoadmap,
    SeifCoinRoadmap,
    TrezorPartner,
    RevolutPartner,
    BinancePartner,
    PulsechainPartner,
    BinancesmartchainPartner,
    EthereumPartner,
    OlympusPartner,
    MasterCardPartner,
    AmazonPartner,
    CuretokenPartner,
    AvaxPartner,
    Cointelegraph,
    Gnosis,
    Visa,
} from '../../../assets/images';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardGroup, Carousel } from 'react-bootstrap';

const Benefits: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id={'benefits'} className="benefits-bg">
                <Container className="spacing-up">
                    <Row>
                        <Col>
                            <img className="benefits-image" src={BenefitsImage} alt="AboutUs" />
                        </Col>
                        <Col md="auto" className="spacing-benefits-blocks">
                            <img src={IndividualImage} className="zooming-block" />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.individual'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.individualDesc'} />
                            </p>
                        </Col>
                        <Col className="spacing-benefits-blocks">
                            <img src={QualityImage} className="zooming-block" />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.quality'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.qualityDesc'} />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto" className="spacing-benefits-blocks">
                            <img src={SecurityImage} className="zooming-block" />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.security'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.securityDesc'} />
                            </p>
                        </Col>
                        <Col className="spacing-benefits-blocks">
                            <img src={NewsImage} className="zooming-block" />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.news'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.newsDesc'} />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto" className="spacing-benefits-blocks">
                            <img src={InvestImage} className="zooming-block" />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.invest'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.investDesc'} />
                            </p>
                        </Col>
                        <Col className="spacing-benefits-blocks">
                            <img src={EducationImage} className="zooming-block" />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.education'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.educationDesc'} />
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="spacing-down">
                    <Row>
                        <span className="roadmap-title">Roadmap</span>
                    </Row>
                    <CardGroup>
                        <Card></Card>
                        <Card>
                            <Card.Body>
                                <Card.Img className="roadmap-image" src={MainHashRoadmap} />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Img className="roadmap-image" src={BtcFondRoadmap} />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Img className="roadmap-image" src={SeifGroupRoadmap} />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Img className="roadmap-image" src={SeifCoinRoadmap} />
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Container>
                <Container className="spacing-down">
                    <Row>
                        <span className="roadmap-title">Partners</span>
                    </Row>
                    <Carousel controls={false}>
                        <Carousel.Item interval={2000}>
                            <CardGroup>
                                <Card></Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={TrezorPartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={RevolutPartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={BinancePartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={PulsechainPartner} />
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                            <CardGroup>
                                <Card></Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={BinancesmartchainPartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={EthereumPartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={OlympusPartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={MasterCardPartner} />
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <CardGroup>
                                <Card></Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={AmazonPartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={Cointelegraph} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={AvaxPartner} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={Visa} />
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                            <CardGroup>
                                <Card></Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={Gnosis} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Img className="partners-image" src={CuretokenPartner} />
                                    </Card.Body>
                                </Card>
                                <Card></Card>
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </Container>
                {/* <div className="container pt-5">
                    <div className="benefits-image">
                        <img src={BenefitsImage} alt="AboutUs" />
                    </div>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col zooming-block">
                            <img src={IndividualImage} />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.individual'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.individualDesc'} />
                            </p>
                        </div>
                        <div className="col zooming-block">
                            <img src={QualityImage} />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.quality'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.qualityDesc'} />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container my-5">
                    <div className="row">
                        <div className="col"></div>
                        <div className="col zooming-block">
                            <img src={SecurityImage} />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.security'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.security'} />
                            </p>
                        </div>
                        <div className="col zooming-block">
                            <img src={NewsImage} />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.news'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.newsDesc'} />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col"></div>
                        <div className="col zooming-block">
                            <img src={InvestImage} />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.invest'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.investDesc'} />
                            </p>
                        </div>
                        <div className="col zooming-block">
                            <img src={EducationImage} />
                            <h5 className="benefits-title-text mt-4">
                                <Trans i18nKey={'landing.benefits.education'} />
                            </h5>
                            <p className="benefits-text-desc mt-3">
                                <Trans i18nKey={'landing.benefits.educationDesc'} />
                            </p>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    );
};

export default Benefits;
