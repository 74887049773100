import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../components/loading-indicator';
import Paginator from '../../../components/paginator';
import { useDebounce } from '../../../hooks/use-debounce';
import { UserProfileType } from '../../../models/api/auth-models';
import { EmployeeStatisticResponseType, EmployeeStatisticTotalItem, PagebleType } from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import EmployeeItem from './employee-item';
import EmployeeWidget from './employee-widget';

const Employees: React.FC = () => {
    const { t } = useTranslation();

    const [employees, setEmployees] = useState<UserProfileType[]>([]);
    const [employeeStatistics, setEmployeeStatistics] = useState<EmployeeStatisticResponseType>();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [pagination, setPagination] = useState<PagebleType<UserProfileType>>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState<EmployeeStatisticTotalItem[]>([]);
    const [activeMonth, setActiveMomnth] = useState<number>(new Date().getMonth());

    const debounceSearch = useDebounce<string>(name.trim(), 600);
    const debounceEmailSearch = useDebounce<string>(email.trim(), 600);

    const loadEmployees = (search: string, searchEmail: string, page: number) => {
        setLoading(true);
        adminService
            .getEmployees(search, searchEmail, { page })
            .then((res) => {
                setEmployees(res.data.employees.content);
                setPagination(res.data.employees);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadStatisticTotal();
    }, []);

    useEffect(() => {
        loadEmployees(debounceSearch, debounceEmailSearch, pageIndex);
    }, [pageIndex, debounceSearch, debounceEmailSearch]);

    const loadStatistics = (id: number, month = new Date().getMonth()) => {
        setActiveMomnth(month);
        adminService.loadEmployeeStatistics(id, (month % 12) + 1).then((res) => {
            setEmployeeStatistics(res.data);
        });
    };

    const loadStatisticTotal = () => {
        adminService.getEmployeeStatisticsTotal().then((res) => {
            setTotal(res.data);
        });
    };

    return (
        <>
            <LoadingIndicator visible={loading} />
            <div className="affiliate-grid-container client-container">
                <div className="search-wrapper mb-2">
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            {t('common.nameSearch')}
                        </label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            Email Search
                        </label>
                    </div>
                </div>
                <Row>
                    <Col xs={9} md={9} lg={9}>
                        <Accordion defaultActiveKey="0">
                            {employees.map((i, index) => (
                                <EmployeeItem
                                    key={index}
                                    eventKey={`key${index}`}
                                    item={i}
                                    month={activeMonth}
                                    onMonthChange={loadStatistics}
                                    employeeStatistics={employeeStatistics}
                                    loadStatistics={loadStatistics}
                                />
                            ))}
                        </Accordion>
                        <Paginator
                            pageIndex={pageIndex}
                            dataLength={pagination?.totalElements}
                            onPageChanged={(newPage: number) => {
                                setPageIndex(newPage);
                            }}
                            pageSize={20}
                        />
                    </Col>
                    <Col xs={3} md={3} lg={3}>
                        <div>{<EmployeeWidget data={total} />}</div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Employees;
