import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((req) => {
    const token = window.localStorage.getItem('TOKEN');

    if (token) req.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    return req;
});

axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response?.status === 401) {
            window.localStorage.clear();
        }
        throw err;
    },
);

//('AUTH TOKEN FROM INSTANCE');

export default axiosInstance;
