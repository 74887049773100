import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { ColorResult, PhotoshopPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { WithdrawHistoryResponseType, WithdrawStatisticItemType } from '../../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../../utils/utils';
import UserLogo from './../../../../components/user-logo';

type PropsType = {
    item: WithdrawStatisticItemType;
    eventKey: string;
    withdrawHistory?: WithdrawHistoryResponseType;
    loadHistory: (id: number, page: number) => void;
    updateColor: (color: string, id: number) => void;
};

const WithdrawItem: React.FC<PropsType> = ({ item, eventKey, loadHistory, withdrawHistory, updateColor }) => {
    const { t } = useTranslation();

    const [colorPicker, setColorPicker] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');

    return (
        <div className="affiliate-item">
            <div
                className="affilate-color"
                style={{ backgroundColor: item.adminColor || '#cecece' }}
                onClick={() => {
                    setColorPicker(true);
                }}
            ></div>
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text">{item.parentLabel}</div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.actualActiveTradingProfit)}</div>
                    <div className="secondary-text">{formatCurrency(item.totalActiveTradingWithdrawal)}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.actualBonusProfit)}</div>
                    <div className="secondary-text">{formatCurrency(item.totalBonusWithdrawal)}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.totalInvest)}</div>
                    <div className="secondary-text">{formatCurrency(item.currentBalance)}</div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action">
                    <ContextAwareToggle eventKey={eventKey} callback={() => loadHistory(item.id, 0)} />
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <div className="item-portfolio-container">
                    <>
                        <div className="item-info-container ">
                            <div className="affilate-amount justify-content-start">
                                <div className="secondary-text align-items-center">Withdrawal statistic :</div>
                            </div>
                            <div className="affilate-amount justify-content-start">
                                <div className="secondary-text">Bonus profits</div>
                                {withdrawHistory?.bonusWithdrawalHistory.map((item) => (
                                    <>
                                        <div className="primary-text">{formatCurrency(item.amount)}</div>
                                        <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                    </>
                                ))}
                                {/* <div className="secondary-text align-items-center">{`${user.name} ${user.surname}`}</div> */}
                            </div>
                            <div className="affilate-amount justify-content-start">
                                <div className="secondary-text">Active trading</div>
                                {withdrawHistory?.activeWithdrawalHistory.map((item) => (
                                    <>
                                        <div className="primary-text">{formatCurrency(item.amount)}</div>
                                        <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                    </>
                                ))}
                                {/* <div className="secondary-text align-items-center">{`${user.name} ${user.surname}`}</div> */}
                            </div>
                            <div className="affilate-amount justify-content-start">
                                <div className="secondary-text">History invest</div>
                                {withdrawHistory?.investments.map((item) => (
                                    <>
                                        <div className="primary-text">{formatCurrency(item.amount)}</div>
                                        <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                    </>
                                ))}
                                {/* <div className="secondary-text align-items-center">{`${user.name} ${user.surname}`}</div> */}
                            </div>
                            <div className="affilate-amount justify-content-start">
                                <div className="secondary-text">History withdrawal</div>
                                {withdrawHistory?.fullWithdrawalHistory.map((item) => (
                                    <>
                                        <div className="primary-text">{formatCurrency(item.amount)}</div>
                                        <div className="secondary-text">{moment(item.date).format('DD.MM.YYYY')}</div>
                                    </>
                                ))}
                                {/* <div className="secondary-text align-items-center">{`${user.name} ${user.surname}`}</div> */}
                            </div>
                        </div>
                    </>
                    {!withdrawHistory && (
                        <div className="portfolio-empty">
                            <span>{t('affilate.empty-list')}</span>
                        </div>
                    )}
                </div>
            </Accordion.Collapse>
            {colorPicker && (
                <div className="color-picker">
                    <PhotoshopPicker
                        color={value}
                        onChange={(color: ColorResult) => setValue(color.hex)}
                        onAccept={() => {
                            updateColor(value, item.id);
                            setColorPicker(false);
                        }}
                        onCancel={() => {
                            setColorPicker(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show'}
        </button>
    );
}

export default WithdrawItem;
