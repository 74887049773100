import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import LoadingIndicator from '../../../components/loading-indicator';
import Paginator from '../../../components/paginator';
import { useDebounce } from '../../../hooks/use-debounce';
import { ClientProfileType, DeleteNoteRequest, PagebleType } from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import AdminCLientsItem from './admin-clients-item';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

const AdminClients: React.FC = () => {
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const [clients, setClients] = useState<ClientProfileType[]>([]);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [pagination, setPagination] = useState<PagebleType<ClientProfileType>>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const debounceSearch = useDebounce<string>(name.trim(), 600);
    const debounceEmailSearch = useDebounce<string>(email.trim(), 600);

    const loadClients = async (search: string, searchEmail: string, page: number) => {
        setLoading(true);
        return adminService
            .getClients(search, searchEmail, { page })
            .then((res) => {
                setClients(res.data.clients.content);
                setPagination(res.data.clients);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deactivateClient = (id: number) => {
        adminService.disableUser(id).then(async () => {
            await loadClients(debounceSearch, debounceEmailSearch, pageIndex);
            addToast(t('toast.disableUser'));
        });
    };

    const deleteUserNote = (data: DeleteNoteRequest) => {
        adminService.deleteUserNote(data).then(async () => {
            await loadClients(debounceSearch, debounceEmailSearch, pageIndex);
            addToast(t('toast.deleteNote'));
        });
    };

    useEffect(() => {
        loadClients(debounceSearch, debounceEmailSearch, pageIndex);
    }, [pageIndex, debounceSearch, debounceEmailSearch]);

    return (
        <>
            <LoadingIndicator visible={loading} />
            <div className="affiliate-grid-container client-container">
                <div className="search-wrapper">
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            {t('common.nameSearch')}
                        </label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            Email Search
                        </label>
                    </div>
                </div>
                <Accordion defaultActiveKey="0">
                    {clients.map((i, index) => (
                        <AdminCLientsItem
                            key={index}
                            eventKey={`key${index}`}
                            item={i}
                            handleDeactivateClient={deactivateClient}
                            handleDeleteNote={deleteUserNote}
                        />
                    ))}
                </Accordion>
                <Paginator
                    pageIndex={pageIndex}
                    dataLength={pagination?.totalElements}
                    onPageChanged={(newPage: number) => {
                        setPageIndex(newPage);
                    }}
                    pageSize={20}
                />
                <div>{/* <UserCountWidget /> */}</div>
            </div>
        </>
    );
};

export default AdminClients;
