import React from 'react';
import UserLogo from '../../../../components/user-logo';
import { generateShortUserName } from '../../../../utils/utils';
import { useUser } from './../../../../context/user-context';

const ProfileUserInfo: React.FC = () => {
    const { user } = useUser();
    return (
        <div className="user-info-wrapper">
            <div className="profile-image">
                <UserLogo name={generateShortUserName(user?.name, user?.surname)} small />
            </div>
            <div className="profile-info">
                <div className="primary-text">{`${user?.name} ${user?.surname}`}</div>
                <div className="additional-text">{user?.phone}</div>
            </div>
            <div className="profile-info">
                <div className="primary-text">-</div>
                <div className="secondary-text flex-end"> - </div>
                <div className="additional-text">{user?.email}</div>
            </div>
            <div className="profile-info">
                <div className="primary-text">-</div>
                <div className="secondary-text flex-end">-</div>
            </div>
        </div>
    );
};

export default ProfileUserInfo;
