import ApexCharts from 'apexcharts';
import React, { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PortfolioHistoryType } from '../../../../models/api/dashboard-model';
import { ChartDateEnum, HistoryDataEnum } from '../../../../models/enums';
import { addMonths } from '../../../../utils/utils';

type PropsType = {
    history?: PortfolioHistoryType;
};

type ColorsType = {
    [key: string]: {
        markerColor: string;
        color: string;
    };
};

const colors: ColorsType = {
    ACTIVE: {
        color: 'rgba(62,134, 176, 0.6)',
        markerColor: 'rgba(62,134, 176, 1)',
    },
    ESTABLISHED: {
        color: 'rgba(170, 60, 120, 0.6)',
        markerColor: 'rgba(170, 60, 120, 1)',
    },
    STARTUP: {
        color: 'rgba(100, 56, 171, 0.6)',
        markerColor: 'rgba(100, 56, 171, 1)',
        // backgroundColor: 'rgba(#6438ab, 0.2)',
        // borderColor: '#6438ab',
    },
    BALANCE: {
        color: 'rgba(108, 119, 178, 0.6)',
        markerColor: 'rgba(108, 119, 178, 1)',
    },
};

const DashboardChart: React.FC<PropsType> = ({ history }) => {
    const { t } = useTranslation();

    const [activeType, setActiveType] = useState<HistoryDataEnum>(HistoryDataEnum.BALANCE);

    const activeData = useMemo(() => {
        return history?.[activeType]?.map((item) => [new Date(item.date).getTime(), item.amount]) || [];
    }, [history, activeType]);

    const options: ApexCharts.ApexOptions = useMemo(() => {
        return {
            chart: {
                id: 'area-datetime',
                type: 'area',
                height: 350,
                zoom: {
                    autoScaleYaxis: true,
                },
                toolbar: {},
                foreColor: '#ccc',
            },
            grid: {
                borderColor: '#555',
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            colors: [colors[activeType].color],
            annotations: {},
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 5,
                colors: [colors[activeType].markerColor],
                strokeColors: colors[activeType].markerColor,
                strokeWidth: 1,
            },
            yaxis: {
                tickAmount: 4,
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 6,
            },
            tooltip: {
                theme: 'dark',
                x: {
                    format: 'dd MMM yyyy',
                },
                y: {
                    title: {
                        formatter: () => activeType,
                    },
                },
            },

            fill: {
                type: 'gradient',
                gradient: {
                    gradientToColors: ['#090D18', '#0D192B'],
                    shadeIntensity: 1,
                    opacityFrom: 0.8,
                    opacityTo: 0.9,
                    stops: [0, 100],
                },
            },
        };
    }, [activeType]);

    const updateView = (timeline: ChartDateEnum) => {
        switch (timeline) {
            case ChartDateEnum.ONE_MONTH:
                ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -1).getTime(), new Date().getTime());
                break;
            case ChartDateEnum.SIX_MONTH:
                ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -6).getTime(), new Date().getTime());
                break;
            case ChartDateEnum.YEAR:
                ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -12).getTime(), new Date().getTime());
                break;
            case ChartDateEnum.ALL:
                ApexCharts.exec('area-datetime', 'zoomX', new Date('01 Mar 2021').getTime(), new Date().getTime());
                break;
            default:
        }
    };

    return (
        <div className="dashboard-chart-wrapper chart">
            <div className="chart-header">
                <div></div>
                <div className="chart-portfolio-wrapper">
                    {Object.values(HistoryDataEnum).map((item, index) => (
                        <div key={index} onClick={() => setActiveType(item)} className={`portfolio-item ${activeType === item ? 'active-item' : ''}`}>
                            {item}
                        </div>
                    ))}
                </div>
                <div className="report-type">
                    <button className="base-btn p-2" onClick={() => updateView(ChartDateEnum.ONE_MONTH)}>
                        1M
                    </button>
                    <button className="base-btn p-2" onClick={() => updateView(ChartDateEnum.SIX_MONTH)}>
                        6M
                    </button>
                    <button className="base-btn p-2" onClick={() => updateView(ChartDateEnum.YEAR)}>
                        1Y
                    </button>
                    <button className="base-btn p-2" onClick={() => updateView(ChartDateEnum.ALL)}>
                        ALL
                    </button>
                </div>
            </div>
            <div className="chart-wrapper">
                <ReactApexChart options={options} series={[{ data: activeData }]} type="area" height={350} />
            </div>
        </div>
    );
};

export default DashboardChart;
