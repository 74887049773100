import React from 'react';

type PropsType = {
    visible: boolean;
    bg?: boolean;
};

const LoadingIndicator: React.FC<PropsType> = ({ visible, bg }) => {
    return (
        <>
            {visible && (
                <div className={`loader-container ${bg && 'bg-dark'}`}>
                    <div className="lds-default">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LoadingIndicator;
