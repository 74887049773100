import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router';
import RefferalSendModal from '../../../components/modals/refferal-send-modal';
import RefferalLinkWidget from '../../../components/wigets/refferral-link-widget';
import { useUser } from '../../../context/user-context';
import { BaseFormType } from '../../../models/models';
import Routes, { ClientDashboardRoutes } from '../../../routes/routes';
import { generateShortUserName } from '../../../utils/utils';
import UserLogo from './../../../components/user-logo';
import { ReferralFormType } from './refferals';

type PropsType = BaseFormType<ReferralFormType> & {
    loading: boolean;
    refferalLink: string;
    usersCount: number;
    onClose: () => void;
    onCoppyLink: () => void;
    onSendLink: () => void;
    handleSend: () => void;
};

const RefferalsView: React.FC<PropsType> = ({
    refferalLink,
    onCoppyLink,
    usersCount,
    onSendLink,
    handleSend,
    loading,
    onClose,
    register,
    errors,
    trigger,
}) => {
    const { t } = useTranslation();
    const { user } = useUser();

    return (
        <div className="refferal-contaienr">
            <Container>
                <Row>
                    <Col>
                        <div className="refаeral-tree">
                            <div className="tree-first-column">
                                <div className="border-out">
                                    <div className="border-in">
                                        <UserLogo name={generateShortUserName(user?.name, user?.surname)} />
                                    </div>
                                </div>
                                <div className="user-name">{`${user?.name} ${user?.surname}`}</div>
                            </div>
                            <div className="tree-second-column">
                                <div className="border-out">
                                    <div className="border-in">
                                        <span>7%</span>
                                    </div>
                                </div>
                                <div className="border-out">
                                    <div className="border-in">
                                        <span>7%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="tree-third-column">
                                <div className="tree-third-wrapper">
                                    <div className="border-out">
                                        <div className="border-in">
                                            <span>3%</span>
                                        </div>
                                    </div>
                                    <div className="circle-wrapper">
                                        <div className="small-circle"></div>
                                        <div className="small-circle"></div>
                                        <div className="small-circle"></div>
                                    </div>
                                </div>

                                <div className="tree-third-wrapper">
                                    <div className="border-out">
                                        <div className="border-in">
                                            <span>3%</span>
                                        </div>
                                    </div>
                                    <div className="circle-wrapper">
                                        <div className="small-circle"></div>
                                        <div className="small-circle"></div>
                                        <div className="small-circle"></div>
                                    </div>
                                </div>

                                <div className="tree-third-wrapper">
                                    <div className="border-out">
                                        <div className="border-in">
                                            <span>3%</span>
                                        </div>
                                    </div>
                                    <div className="circle-wrapper">
                                        <div className="small-circle"></div>
                                        <div className="small-circle"></div>
                                        <div className="small-circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="refferal-info">
                        <p>
                            <Trans i18nKey="refferals.infoText" />
                        </p>
                    </Col>
                </Row>
            </Container>

            <Row>
                <Col lg={10} md={6} xs={12}>
                    <div>{user?.isInvested && <RefferalLinkWidget link={refferalLink} onCopy={onCoppyLink} onSend={onSendLink} />}</div>
                </Col>
                <Col lg={2} md={6} xs={12}>
                    <div className="users-widget">
                        <div className="users-count">{usersCount}</div>
                        <div className="users-title">{t('refferals.registeredPeople')}</div>
                    </div>
                </Col>
            </Row>

            <Route
                path={`${ClientDashboardRoutes.REFFERALS}${Routes.SEND_REFERAL}`}
                exact
                // eslint-disable-next-line react/no-children-prop
                children={({ match }) => (
                    <RefferalSendModal
                        isOpened={Boolean(match)}
                        onClose={onClose}
                        loading={loading}
                        handleSend={handleSend}
                        register={register}
                        errors={errors}
                        trigger={trigger}
                    />
                )}
            />
            <Redirect to={ClientDashboardRoutes.REFFERALS} />
        </div>
    );
};

export default RefferalsView;
