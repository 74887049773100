import React from 'react';
import { Card, Button, Col, Container, Row, CardGroup } from 'react-bootstrap';
import DocumentItem from './components/document-item';
import { useTranslation } from 'react-i18next';

const DocumentsView: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="documents-container">
            <DocumentItem urlPath="docs/GDPR.pdf" name="GDPR" color="#b82d7b" />
            <DocumentItem urlPath="docs/Podmienky_a_ustanovenia.pdf" name="Všeobecné obchodné podmienky" color="#6e79b0" />
            <DocumentItem urlPath="docs/Dohoda.pdf" name="Dohoda o administrácii kryptomien" color="#5e4a92" />
            <DocumentItem urlPath="docs/CWPOPD.pdf" name="GDPR - ENG" color="#b82d7b" />
            <DocumentItem urlPath="docs/terms.pdf" name="Terms & Conditions" color="#6e79b0" />
            <DocumentItem urlPath="docs/Agreement_on_Administration_of_Cryptocurrencies.pdf" name="Agreement" color="#5e4a92" />
            <div className="tutorial-title">
                <span className="text-white">{t('documents.documentsHowItWork')}</span>
            </div>
            <CardGroup className="my-5">
                <Card>
                    <Card.Body>
                        <Row>
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/1XwOLyAoDvU?start=2"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </Row>
                    </Card.Body>
                </Card>
                <Card></Card>
                <Card></Card>
            </CardGroup>
        </div>
    );
};

export default DocumentsView;
