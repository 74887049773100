import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { ResetDataType } from '../../../models/api/auth-models';
import { RouteModalPropsType } from '../../../models/models';
import { AuthRoutes } from '../../../routes/routes';
import authService from './../../../service/authService';
import ResetPasswordView from './reset-password-view';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC<RouteModalPropsType> = ({ isOpened, onClose }) => {
    const history = useHistory();
    const { addToast } = useToasts();
    const urlQuery = useUrlQuery();
    const { t } = useTranslation();
    const token = urlQuery.get('token') ?? '';

    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        trigger,
    } = useForm<ResetDataType>();

    const handleReset = (data: ResetDataType) => {
        setLoading(true);
        authService.resetPassword({ ...data, forgotPasswordToken: token }).then(onSuccess, onError);

        function onSuccess() {
            setLoading(false);
            addToast(t('toast.passwordChanged'));
            history.replace(AuthRoutes.LOGIN);
        }

        function onError() {
            setLoading(false);
            addToast(t('toast.linkNotValid'), { appearance: 'error' });
        }
    };

    return (
        <>
            <ResetPasswordView
                handleClose={onClose}
                handleReset={handleSubmit(handleReset)}
                register={register}
                errors={errors}
                trigger={trigger}
                watch={watch}
                loading={loading}
                isOpened={isOpened}
            />
        </>
    );
};

export default ResetPassword;
