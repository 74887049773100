import moment from 'moment';
import React, { useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ProfitsResponseType } from '../../../../models/api/dashboard-model';
import { formatCurrency } from '../../../../utils/utils';

type PropsType = Pick<ProfitsResponseType, 'bonusesByMonths'> & {
    refferalAmount?: number;
};

const DashboardRefferal: React.FC<PropsType> = ({ refferalAmount, bonusesByMonths }) => {
    const { t } = useTranslation();

    const getPercentFromAmount = useCallback(
        (value: number) => {
            if (!refferalAmount) return 0;
            return (value * 100) / refferalAmount;
        },
        [refferalAmount],
    );

    return (
        <div className="refferal refferal-container">
            <div className="refferal-info">
                <div className="info-wrapper">
                    <div className="info-percent">{formatCurrency(refferalAmount)}</div>
                    <div className="info-text">{t('dashboard.refferals')}</div>
                </div>
                {/* <FontAwesomeIcon icon={faSync} color="#6d717d" role="button" /> */}
                <div></div>
            </div>
            <div className="refferal-statistics">
                <div className="statistics-percentage"></div>
                <div className="statistic-item-list">
                    {bonusesByMonths &&
                        Object.entries(bonusesByMonths)
                            .reverse()
                            .map(([date, value]) => (
                                <OverlayTrigger
                                    key={date}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`id-d`} placement="top">
                                            {formatCurrency(value)}
                                        </Tooltip>
                                    }
                                >
                                    <div className="statistic-item">
                                        <div className="item-month">{moment(date, 'M').format('MMM')}</div>
                                        <div className="item-progress">
                                            <div className="progress-view " style={{ width: `${getPercentFromAmount(value)}%` }}></div>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            ))}
                </div>
            </div>
        </div>
    );
};
export default DashboardRefferal;
