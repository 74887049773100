//@ts-nocheck
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { useUser } from '../../context/user-context';
import { useLocalStorage } from '../../hooks/use-local-storage';
import usePrepareLink from '../../hooks/use-prepare-link';
import Routes, { AuthRoutes } from '../../routes/routes';
import NotFound404 from './../../components/NotFond404';
import { TOKEN } from './../../models/const';
import LandingRouteList from './../../routes/landing-route-list';
import LandingHeader from './components/landing-header';
import SectionAboutUs from './sections/about-us';
import Benefits from './sections/benefits';
import ContactUs from './sections/contact-us';
import Products from './sections/products';
import SectionHome from './sections/section-home';

const LandingPage: React.FC = () => {
    const history = useHistory();
    const { value: token } = useLocalStorage<string>(TOKEN, '');
    const { user } = useUser();

    const aboutRef = useRef<null | HTMLElement>(null);
    const benefitsRef = useRef<null | HTMLElement>(null);
    const contactRef = useRef<null | HTMLElement>(null);
    const productsRef = useRef<null | HTMLElement>(null);
    const homeRef = useRef<null | HTMLElement>(null);

    const signInLink = usePrepareLink({
        to: AuthRoutes.LOGIN,
        isRelativePath: false,
    });

    const signUpLink = usePrepareLink({
        to: AuthRoutes.REGISTRATION,
        isRelativePath: false,
    });

    const handleLogin = () => {
        history.push(signInLink);
    };
    const handleRegister = () => {
        history.push(signUpLink);
    };

    const handleModalClose = (root?: boolean) => {
        if (root) return history.replace(Routes.ROOT);

        return history.goBack();
    };

    const handleScroll = (to: string) => {
        switch (to) {
            case 'home': {
                return homeRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
            case 'about': {
                return aboutRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
            case 'products': {
                return productsRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
            case 'benefits': {
                return benefitsRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
            case 'contact': {
                return contactRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
            default:
                return;
        }
    };

    return (
        <>
            <div className="landing-container home-bg">
                <LandingHeader onLoginClick={handleLogin} onRegisterClick={handleRegister} handleLinkClick={handleScroll} />
                <div className="box-shadow-effect"></div>
            </div>
            {!user && <LandingRouteList onClose={handleModalClose} />}
            <Route path={'*'} component={NotFound404} />
            <div ref={homeRef}>
                <SectionHome />
            </div>
            <div ref={aboutRef}>
                <SectionAboutUs />
            </div>
            <div ref={productsRef}>
                <Products />
            </div>
            <div ref={benefitsRef}>
                <Benefits />
            </div>
            <div ref={contactRef}>
                <ContactUs />
            </div>
        </>
    );
};
export default LandingPage;
