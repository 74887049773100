import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BaseInput from '../../../components/common/base-input';
import DatePicker from '../../../components/datePicker/datePicker';
import LoadingIndicator from '../../../components/loading-indicator';
import Paginator from '../../../components/paginator';
import { useDebounce } from '../../../hooks/use-debounce';
import {
    WithdrawHistoryResponseType,
    WithdrawStatisticItemType,
    WithdrawStatisticResponseType,
    WithdrawTotalResponseType,
} from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import WithdrawBonusWidget, { WithdrawBonusWidgetEnum } from './components/withdraw-bonus-widget';
import WithdrawItem from './components/withdraw-item';
import WithdrawalWidget from './components/withdrawal-widget';

type SearchFormType = {
    date: string;
};

const WithdrawStatistic: React.FC = () => {
    const { t } = useTranslation();

    const [name, setName] = useState<string>('');
    const [clients, setClients] = useState<WithdrawStatisticItemType[]>([]);
    const [pagination, setPagination] = useState<WithdrawStatisticResponseType>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [withdrawlHistory, setWithdrawalHistory] = useState<WithdrawHistoryResponseType>();
    const [total, setTotal] = useState<WithdrawTotalResponseType>();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        watch,
        formState: { errors },
        trigger,
        setValue,
    } = useForm<SearchFormType>();

    const date = watch('date');

    const debounceSearch = useDebounce<string>(name.trim(), 600);

    useEffect(() => {
        adminService.getWithdrawTotal().then((res) => {
            setTotal(res.data);
        });
    }, []);

    const loadStatistics = (search: string, date: string, page: number) => {
        setLoading(true);
        adminService
            .getWithdrawStatistics(search, date, { page })
            .then((res) => {
                setClients(res.data.content);
                setPagination(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadStatistics(debounceSearch, date, pageIndex);
    }, [pageIndex, date, debounceSearch]);

    const loadHistory = (userId: number, page: number) => {
        setLoading(true);
        adminService
            .getWithdrawHistories(userId, date, { size: 10, page })
            .then((res) => {
                setWithdrawalHistory(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateColor = (color: string, id: number) => {
        adminService.updateColor(color, id).then(() => {
            loadStatistics(debounceSearch, date, pageIndex);
        });
    };

    return (
        <div className="statistic-container">
            <div className="search-wrapper">
                <div className="form-floating">
                    <input
                        type="text"
                        id=""
                        placeholder=" "
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                    <label htmlFor="nameSearch" className="form-label mb-2">
                        Name Search
                    </label>
                </div>
                <div className="date-picker-wrapper">
                    <DatePicker setValue={setValue} maxDate={new Date()} name="date">
                        <BaseInput {...register('date')} errors={errors} trigger={trigger} type="text" maxLength={10} label={t('auth.date')} />
                    </DatePicker>
                </div>
            </div>
            <div className="grid-container">
                <div className="statistic-list">
                    <div className="client-list-header ">
                        <div>Name / Refferals</div>
                        <div>
                            Actual bonus profits <br />
                            Total withdrawal
                        </div>
                        <div>
                            Actual active trading <br />
                            Total withdrawal
                        </div>
                        <div>
                            Total invest <br />
                            Current balance
                        </div>
                    </div>
                    <Accordion defaultActiveKey="0">
                        {clients.map((i, index) => (
                            <WithdrawItem
                                key={index}
                                eventKey={`key${index}`}
                                item={i}
                                withdrawHistory={withdrawlHistory}
                                loadHistory={loadHistory}
                                updateColor={updateColor}
                            />
                        ))}
                        {clients.length === 0 && (
                            <div className="portfolio-empty">
                                <span>{t('affilate.empty-list')}</span>
                            </div>
                        )}
                    </Accordion>
                    <LoadingIndicator visible={loading} />
                    <Paginator
                        pageIndex={pageIndex}
                        dataLength={pagination?.totalElements}
                        onPageChanged={(newPage: number) => {
                            setPageIndex(newPage);
                        }}
                        pageSize={20}
                    />
                </div>
                <div className="statistic-widgets">
                    {total && <WithdrawalWidget data={total.payoutRequestHistory} />}
                    <WithdrawBonusWidget data={total?.payoutRequestFromBonusHistory} type={WithdrawBonusWidgetEnum.BONUS} />
                    <WithdrawBonusWidget data={total?.payoutRequestFromActiveTradingHistory} type={WithdrawBonusWidgetEnum.ACTUAL} />
                </div>
            </div>
        </div>
    );
};

export default WithdrawStatistic;
