import React from 'react';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { SeifLogo, MobileLogo } from '../../../assets/images';
import { useUser } from '../../../context/user-context';
import { useLocalStorage } from '../../../hooks/use-local-storage';
import { RolesEnum } from '../../../models/enums';
import Routes, { AuthRoutes } from '../../../routes/routes';
import BaseButton from './../../../components/common/base-button';
import { TOKEN } from './../../../models/const';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faChartBar, faChartLine, faDoorOpen, faRocket, faSignInAlt, faSnowboarding } from '@fortawesome/free-solid-svg-icons';
import LandingRouteList from '../../../routes/landing-route-list';

type PropsType = {
    onLoginClick: () => void;
    onRegisterClick: () => void;
    handleLinkClick: (refName: string) => void;
};

const LandingHeader: React.FC<PropsType> = ({ onLoginClick, handleLinkClick }) => {
    const { user } = useUser();
    const history = useHistory();
    const { value: token } = useLocalStorage(TOKEN, '');
    const { t } = useTranslation();

    const redirectToDashboard = () => {
        if (user?.roles?.includes(RolesEnum.ADMIN)) return history.push(Routes.ADMIN);
        if (user?.roles?.includes(RolesEnum.EMPLOYEE)) return history.push(Routes.EMPLOYEE);
        if (user?.roles?.includes(RolesEnum.USER)) return history.push(Routes.USER);
        return history.push(AuthRoutes.LOGIN);
    };

    return (
        <>
            <Row className="landing-header-container">
                {/* <Col xs={1}></Col>
                <Col xl={2}>
                    <Link to={Routes.ROOT} role="button" className="header-logo">
                        <img src={SeifLogo} alt="" />
                    </Link>
                </Col> */}
                <Col className="mobile-padding-0">
                    <Navbar collapseOnSelect expand="lg" variant="dark">
                        <Container>
                            <Navbar.Brand>
                                <Link to={Routes.ROOT} role="button">
                                    <img src={SeifLogo} alt="" className="header-logo" />
                                    <img src={MobileLogo} alt="" className="mobile-logo" />
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="m-auto">
                                    <Nav.Link>
                                        <div onClick={() => handleLinkClick('home')} className="nav-link">
                                            {t('navlinks.home')}
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div onClick={() => handleLinkClick('about')} className="nav-link">
                                            {t('navlinks.about')}
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div onClick={() => handleLinkClick('products')} className="nav-link">
                                            {t('navlinks.products')}
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div onClick={() => handleLinkClick('benefits')} className="nav-link">
                                            {t('navlinks.benefits')}
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div onClick={() => handleLinkClick('contact')} className="nav-link">
                                            {t('navlinks.contact')}
                                        </div>
                                    </Nav.Link>
                                </Nav>
                                <Nav>
                                    <Nav.Link eventKey={2}>
                                        <div className="header-auth-btns">
                                            {token && user ? (
                                                <BaseButton className="sign-in-btn" onClick={redirectToDashboard}>
                                                    <span className="px-2">{t('navlinks.dashboard')}</span>
                                                    <FontAwesomeIcon icon={faChartBar} color="white" size="1x" className="social-icon" />
                                                </BaseButton>
                                            ) : (
                                                <div className="log-in-btn-wrap">
                                                    <BaseButton className="log-in-btn" onClick={onLoginClick}>
                                                        <span className="px-2">{t('navlinks.logIn')}</span>
                                                        <FontAwesomeIcon icon={faSignInAlt} color="white" size="1x" className="social-icon" />
                                                    </BaseButton>
                                                </div>
                                            )}
                                        </div>
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    {/* <div className="landing-nav-links">
                        <ul className="landing-nav">
                            <li className="nav-item">
                                <div onClick={() => handleLinkClick('home')} className="nav-link">
                                    {t('navlinks.home')}
                                </div>
                            </li>
                            <li className="nav-item">
                                <div onClick={() => handleLinkClick('about')} className="nav-link">
                                    {t('navlinks.about')}
                                </div>
                            </li>
                            <li className="nav-item">
                                <div onClick={() => handleLinkClick('products')} className="nav-link">
                                    {t('navlinks.products')}
                                </div>
                            </li>
                            <li className="nav-item">
                                <div onClick={() => handleLinkClick('benefits')} className="nav-link">
                                    {t('navlinks.benefits')}
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink to={SectionRoutes.FAQ} exact onClick={() => handleLinkClick('about')} className="nav-link">
                                    {t('navlinks.FAQ')}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <div onClick={() => handleLinkClick('contact')} className="nav-link">
                                    {t('navlinks.contact')}
                                </div>
                            </li>
                        </ul>
                    </div> */}
                </Col>
                {/* <Col xl={2} className="flex-end right">
                    <div className="header-auth-btns">
                        {token ? (
                            <BaseButton className="sign-in-btn" onClick={redirectToDashboard}>
                                {t('navlinks.dashboard')}
                            </BaseButton>
                        ) : (
                            <div className="log-in-btn-wrap">
                                <BaseButton className="log-in-btn" onClick={onLoginClick}>
                                    {t('navlinks.logIn')}
                                </BaseButton>
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={1}></Col> */}
            </Row>
        </>
    );
};

export default LandingHeader;
