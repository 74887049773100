import moment from 'moment';
import React, { useCallback, useState, useMemo } from 'react';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowDown, InwardIcon, OutsideIcon, SlovakFlag, InfoIcon } from '../../../../assets/images';
import { ProfitsResponseType } from '../../../../models/api/dashboard-model';
import { formatCurrency } from '../../../../utils/utils';
import { WithdrawBonusWidgetEnum } from './withdraw-bonus-widget';

type PropsType = {
    data?: { [key: number]: number };
    type: BonusWidgetEnum;
    title?: string;
    isReferal?: boolean;
};

type MonthToggle = {
    onClick: (e: React.MouseEvent) => void;
};

const MonthToggle: React.FC<MonthToggle> = ({ children, onClick }) => (
    <span
        className="month-toggle"
        role="button"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children} {'  '}
        <img src={ArrowDown} alt="" className="income-icon" />
    </span>
);

export enum BonusWidgetEnum {
    PAYOUT = 'PAYOUT',
    INVEST = 'INVEST',
}

const BonusWidget: React.FC<PropsType> = ({ data, title = '', type, isReferal }) => {
    const { t } = useTranslation();
    const [activeMonth, setActiveMonth] = useState<number>(new Date().getMonth() + 1);

    const valueByMonth = useMemo(() => {
        return data?.[+activeMonth];
    }, [data, activeMonth]);

    const maxAmount = useMemo(() => {
        if (!data) return 0;
        return Math.max(...Object.values(data));
    }, [data]);

    const getPercentFromAmount = useCallback(
        (value: number) => {
            return (value * 100) / maxAmount;
        },
        [maxAmount],
    );

    const disclaimerReferral = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('dashboard.disclaimerReferral')}
        </Tooltip>
    );

    return (
        <div className="refferal refferal-container">
            <div className="refferal-info">
                <div className="info-wrapper">
                    <div className="info-text">{title}&nbsp;</div>
                    <div className="info-percent">{formatCurrency(valueByMonth)}</div>
                    <div className="info-text">{}</div>
                </div>
                <div className="widget-icon">
                    {type === BonusWidgetEnum.PAYOUT && <img src={OutsideIcon} alt="outside" />}
                    {type === BonusWidgetEnum.INVEST && <img src={InwardIcon} alt="invest" />}
                    <div className="d-inline">
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={disclaimerReferral}>
                            <Button className="bg-transparent border-0">
                                <img src={InfoIcon} className="info-icon-size" alt="" />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="mt-2">
                    {isReferal && <div className="info-text">{t('dashboard.refferals')}</div>}
                    <Dropdown onSelect={(eventKey: any) => setActiveMonth(eventKey as number)} className="income-dropdown">
                        <Dropdown.Toggle as={MonthToggle} id="dropdown-custom-components">
                            Month: {moment(+activeMonth, 'M').format('MMMM')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {data &&
                                Object.keys(data).map((month) => (
                                    <Dropdown.Item key={month} eventKey={month}>
                                        {moment(month, 'M').format('MMMM')}
                                    </Dropdown.Item>
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="refferal-statistics">
                <div className="statistics-percentage"></div>
                <div className="statistic-item-list">
                    {data &&
                        Object.entries(data)
                            .reverse()
                            .map(([date, value]) => (
                                <OverlayTrigger
                                    key={date}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`id-d`} placement="top">
                                            {formatCurrency(value)}
                                        </Tooltip>
                                    }
                                >
                                    <div className={`statistic-item ${activeMonth == +date ? 'active' : ''} `}>
                                        <div className="item-month">{moment(date, 'M').format('MMM')}</div>
                                        <div className={`item-progress`}>
                                            <div
                                                className={`progress-view  ${type === BonusWidgetEnum.INVEST ? 'invest' : 'payout'}`}
                                                style={{ width: `${getPercentFromAmount(value)}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            ))}
                </div>
            </div>
        </div>
    );
};
export default BonusWidget;
