import classNames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { FieldErrors, UseFormTrigger } from 'react-hook-form';
import { accessNestedProperty } from '../../utils/utils';
import { BankLogo } from '../../assets/images';
import { InputGroup, FormControl } from 'react-bootstrap';

type PropsType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    name: string;
    label?: string | JSX.Element;
    placeholder?: string;
    className?: string;
    errors?: FieldErrors;
    trigger: UseFormTrigger<any>;
    icon?: string;
};

const BaseInput = forwardRef(({ icon, type = 'text', label, placeholder = ' ', className = '', errors, trigger, ...params }: PropsType, ref: any) => {
    const errorProperty = params.name == null ? undefined : accessNestedProperty({ ...errors }, params.name.split('.'));
    const isValid = errors == null || errorProperty == null;

    const [isTouched, setIsTouched] = useState<boolean>(false);
    const manualErrorCheck = useCallback(() => {
        trigger && trigger(params.name);
    }, [params.name, trigger]);

    useEffect(() => {
        !isValid && manualErrorCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualErrorCheck]);

    return (
        <>
            <div className={`form-floating mt-2 d-flex align-items-center ${className}`}>
                <input
                    id={`id-${params.name}`}
                    ref={ref}
                    {...params}
                    type={type}
                    placeholder={placeholder}
                    autoComplete="OFF"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        params.onChange && params.onChange(e);
                        manualErrorCheck();
                        if (isTouched === false) {
                            setIsTouched(true);
                        }
                    }}
                    className={classNames(className || '', 'form-control', {
                        'is-invalid': !isValid,
                        // 'is-valid': isValid && isTouched,
                    })}
                />

                {icon && <img src={icon} className="bank-usdt-size" />}
                <label htmlFor={`id-${params.name}`} className="form-label mb-2">
                    {label}
                </label>
            </div>
            {errors?.[params.name] && <span className="label-error">{errors[params.name].message}</span>}
        </>
    );
});

export default BaseInput;
