enum Routes {
    ROOT = '/',
    USER = '/client',
    ADMIN = '/admin',
    EMPLOYEE = '/employee',
    SEND_REFERAL = '/send',
}

export enum AuthRoutes {
    LOGIN = '/login',
    REGISTRATION = '/register',
    PROCESS_SUCCESS = '/process-success',
    REGISTRATION_EMPLOYEE = '/register-employee',
    REGISTRATION_CLIENT = '/register-client',
    REGISTRATION_REFERRAL = '/register-referral',
    REGISTRATION_CONFIRM = '/confirm-register',
    FORGOT_PASSWORD = '/forgot-passowrd',
    RESET_PASSWORD = '/reset-password',
}

export enum SectionRoutes {}
// HOME = '/',
// RULES = '/rules',
// ABOUT_US = '/about-us',
// CAREER = '/career',
// FAQ = '/faq',
// CONTACT_US = '/contact-us',

export enum ClientDashboardRoutes {
    DASHBOARD = '/client',
    AFFILIATE = '/client/affiliate',
    DOCUMENTS = '/client/documents',
    SOCIAL = '/client/social',
    REFFERALS = '/client/refferals',
    INVEST = '/client/invest',
    REINVEST = '/client/reinvest',
    WITHDRAWAL = '/client/withdrawal',
    FAQ = '/client/faq',
    SUPPORT = '/client/support',
    PROFILE = '/client/profile',
    INBOX = '/client/inbox',
}

export enum AdminRoutes {
    DASHBOARD = '/admin',
    CLIENT_DASHBOARD = '/admin/client-dashboard',
    CLIENT_STATISTIC = '/admin/statistic-client',
    SALE_STATISTIC = '/admin/statistic-sale',
    REFERAL_STATISTIC = '/admin/statistic-referal',
    WITHDRAWAL_STATISTIC = '/admin/statistic-withdraw',
    EMPLOYEES = '/admin/employees',
    AFFILIATE = '/admin/affiliate',
    CLIENTS = '/admin/clients',
    REFFERALS = '/admin/refferals',
    REGISTERED_USERS = '/admin/registered-users',
    TRADES = '/admin/trades',
    WITHDRAWAL_REQUESTS = '/admin/withdrawal-requests',
    ADMIN_CONSOLE = '/admin/console',
    INBOX_MANAGER = '/admin/inbox',
}

export enum EmployeeRoutes {
    DASHBOARD = '/employee',
    CLIENTS = '/employee/clients',
    REFFERALS = '/employee/refferals',
    NETWORK = '/employee/network',
    AFFILIATE = '/employee/affiliate',
    CLIENT_DASHBOARD = '/employee/client-dashboard',
    CLIENT_STATISTIC = '/employee/statistic-client',
    SALE_STATISTIC = '/employee/statistic-sale',
    REFERAL_STATISTIC = '/employee/statistic-referal',
    EMPLOYEES = '/employee/employees',
}

export const GET_PARAMS = {
    regform: 'regform',
};

export const GET_ENUMS = {
    popup: {
        registerMessage: 'registerMessage',
        signUp: 'sign-up',
    },
};

export const AUTH_ROUTES: string[] = [...Object.values(AuthRoutes)];
export const ROUTES: string[] = [...Object.values(ClientDashboardRoutes), ...Object.values(AdminRoutes), ...Object.values(EmployeeRoutes)];
export const ALL_ROUTES: string[] = [
    ...Object.values(AuthRoutes),
    ...Object.values(ClientDashboardRoutes),
    ...Object.values(AdminRoutes),
    ...Object.values(EmployeeRoutes),
];

export default Routes;
