import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardGroup, Col, Dropdown, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BaseInput from '../../../components/common/base-input';
import DatePicker from '../../../components/datePicker/datePicker';
import Paginator from '../../../components/paginator';
import { useNotification } from '../../../context/notification-context';
import { InboxItemType, PagebleType } from '../../../models/api/dashboard-model';
import { InboxTypeDrowpdownEnum } from '../../../models/enums';
import clientService from '../../../service/clientService';
import { inboxColors } from '../../../utils/utils';

type InboxFormType = {
    dateFilter: string;
};

const Inbox: React.FC = () => {
    const { t } = useTranslation();
    const { loadCount, loadAlerts } = useNotification();
    const [type, setType] = useState<InboxTypeDrowpdownEnum>(InboxTypeDrowpdownEnum.ALL);
    const [pagination, setPagination] = useState<PagebleType<InboxItemType>>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [items, setItems] = useState<InboxItemType[]>([]);
    const [activeItem, setActiveItem] = useState<InboxItemType>();
    const [itemContent, setItemContent] = useState<string>();

    const {
        register,
        watch,
        formState: { errors },
        trigger,
        setValue,
    } = useForm<InboxFormType>();

    const dateFilter = watch('dateFilter');

    const loadInbox = useCallback((page: number, dateFilter?: string, type?: InboxTypeDrowpdownEnum) => {
        clientService
            .getInbox({ page }, dateFilter, type, true)
            .then((res) => {
                setItems(res.data.messages.content);
                setPagination(res.data.messages);
                // setNotificationCount(0);
            })
            .finally(() => {
                setItemContent(undefined);
            });
    }, []);

    useEffect(() => {
        const activeType = type === InboxTypeDrowpdownEnum.ALL ? undefined : type;
        loadInbox(pageIndex, dateFilter, activeType);
    }, [type, dateFilter, pageIndex, loadInbox]);

    useEffect(() => {
        if (activeItem) {
            clientService.getInboxContent(activeItem.contentPath).then((res) => {
                setItemContent(res.data);
            });
            if (!activeItem.isRead) clientService.readInbox(activeItem.id);
        }
    }, [activeItem, loadCount, loadAlerts]);

    const handlePreviewClick = (item: InboxItemType) => {
        setActiveItem(item);
        handleShow();
    };

    const rateMessage = (id: number) => {
        const activeType = type === InboxTypeDrowpdownEnum.ALL ? undefined : type;

        clientService.rateMessage(id).then(() => {
            loadInbox(pageIndex, dateFilter, activeType);
        });
    };

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        const activeType = type === InboxTypeDrowpdownEnum.ALL ? undefined : type;
        loadInbox(pageIndex, dateFilter, activeType);
        loadCount();
        loadAlerts();
    };
    const handleShow = () => setShow(true);

    const formatContent = (content: string): string => {
        return content.replaceAll('<p style="color: #fff"></p>', '<p style="color: #fff">&nbsp;</p>');
    };

    return (
        <>
            <div className="inbox-fill-container">
                <Row>
                    <Col>
                        <div className="inbox-title"> {t('navlinks.iboxManager')}</div>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col lg={2} className="d-flex align-items-end">
                        <div className="d-flex align-items-center inbox-category">
                            <Dropdown
                                className="search-typehead"
                                onSelect={(eventKey: any) => {
                                    setType(eventKey as InboxTypeDrowpdownEnum);
                                }}
                            >
                                <Dropdown.Toggle className="mobile-invest-btn inbox-dropdown-btn">{type}</Dropdown.Toggle>

                                <Dropdown.Menu className="">
                                    {Object.values(InboxTypeDrowpdownEnum).map((item, index) => (
                                        <Dropdown.Item eventKey={item} key={index}>
                                            {t(`enums.inboxType.${item}`)}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <div
                                style={{
                                    backgroundColor: inboxColors[type],
                                    height: '20px',
                                    width: '20px',
                                    borderRadius: '50%',
                                    margin: '8px',
                                    border: '1px solid #3d4356',
                                }}
                            ></div>
                        </div>
                    </Col>
                    <Col lg={4} className="date-picker-wrapper">
                        <DatePicker setValue={setValue} maxDate={new Date()} name="dateFilter">
                            <BaseInput
                                {...register('dateFilter')}
                                errors={errors}
                                trigger={trigger}
                                type="text"
                                maxLength={10}
                                label={t('auth.date')}
                            />
                        </DatePicker>
                    </Col>
                </Row>
                <Row className="mt-4 inbox-cards">
                    <Col className="p-0 ">
                        <CardGroup>
                            <Modal show={show} onHide={handleClose} className="inbox-modal">
                                <Modal.Header closeButton className="border-0"></Modal.Header>
                                <Modal.Body>
                                    <Card className="m-4 border-0">
                                        <Card.Body>
                                            <Card.Title className="text-white">{activeItem?.subject}</Card.Title>
                                            <Card.Text className="text-white">
                                                {itemContent && <div dangerouslySetInnerHTML={{ __html: formatContent(itemContent) }}></div>}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Modal.Body>
                                {activeItem && <div className="arrow-up" style={{ borderTop: `20px solid ${inboxColors[activeItem.type]}` }}></div>}
                            </Modal>

                            {items.map((item) => (
                                <Card className="m-4 border-0 custom-card" key={item.id}>
                                    <div className={`${item.isRead ? 'inbox-bg-color-read ' : 'inbox-bg-color'} border-0 p-0`}>
                                        <div className="test">
                                            <Card.Img src={item.imagePreview} />
                                            <div className={`arrow-down ${item.isRead ? 'arrow-bg-color-read ' : 'arrow-bg-color'} `}></div>
                                            <div className="heart-parent">
                                                <a
                                                    className={`heart24 ${item.rated ? 'heart24-on' : 'heart24-off'}`}
                                                    href="javascript:void(0);"
                                                    onClick={() => rateMessage(item.id)}
                                                >
                                                    <span>{item.ratedCount}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <Card.Body onClick={() => handlePreviewClick(item)}>
                                            <Card.Title className="text-white" role="button">
                                                {item.subject}
                                            </Card.Title>
                                            <Card.Text role="button" className="text-muted">
                                                {item.titlePreview.substring(0, 50)}...
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="border-0 bg-transparent my-3">
                                            <div className="d-flex justify-content-between">
                                                <small className="text-muted">{moment(item.createdAt).format('hh:mm')}</small>
                                                <small className="text-muted">{moment(item.createdAt).format('DD.MM.YYYY')}</small>
                                            </div>
                                        </Card.Footer>
                                        <div className="arrow-up" style={{ borderTop: `20px solid ${inboxColors[item.type]}` }}></div>
                                    </div>
                                </Card>
                            ))}
                        </CardGroup>

                        <Paginator
                            pageIndex={pageIndex}
                            dataLength={pagination?.totalElements}
                            onPageChanged={(newPage: number) => {
                                setPageIndex(newPage);
                            }}
                            pageSize={20}
                        />
                    </Col>
                    {/* <Col className="inbox-content">{itemContent && <div dangerouslySetInnerHTML={{ __html: itemContent }}></div>}</Col> */}
                </Row>
            </div>
        </>
    );
};

export default Inbox;
