import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils/utils';

type PropsType = {
    refferalDividents: number;
    firstLevelCount?: number;
    secondLevelCount?: number;
    isClient: boolean;
};

const RefferalUsersWidget: React.FC<PropsType> = ({ refferalDividents, firstLevelCount = 0, secondLevelCount = 0, isClient = false }) => {
    const { t } = useTranslation();

    return (
        <div className="profit-container">
            <div className="profit-amount">{formatCurrency(refferalDividents)}</div>
            <div className="profit-text">{t('affilate.totalProfit')}</div>
            <div className="profit-count">
                <div className="percentage">
                    7%
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                </div>
                <div className="people">{t('affilate.people', { count: firstLevelCount })}</div>
            </div>

            <div className="profit-count">
                <div className="percentage">
                    3%
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                </div>
                <div className="people">{t('affilate.people', { count: secondLevelCount })}</div>
            </div>

            {isClient ? (
                <div className="profit-count">
                    <div className="percentage">Total</div>
                    <div className="people">{t('affilate.people', { count: firstLevelCount + secondLevelCount })}</div>
                </div>
            ) : (
                <div className="profit-count">
                    <div className="percentage">
                        +%
                        <FontAwesomeIcon icon={faMale} className="p-icon" />
                    </div>
                    <div className="people">{t('affilate.people', { count: 0 })}</div>
                </div>
            )}
        </div>
    );
};

export default RefferalUsersWidget;
