import { ApproveIcon, DeclinedIcon, WaitingIcon } from '../../../../assets/images';
import { IdentityDataStatusEnum } from '../../../../models/enums';
import React, { useEffect, useState } from 'react';
import clientService from './../../../../service/clientService';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';

import { ProfileResponseType, ProfileType } from '../../../../models/api/auth-models';
import { Button } from 'react-bootstrap';

const icons: { [key: string]: string } = {
    APPROVED: ApproveIcon,
    DECLINED: DeclinedIcon,
    PENDING: WaitingIcon,
};
type PropsType = {
    data?: ProfileType;
};

const ProfileId: React.FC<PropsType> = ({ data }) => {
    const history = useHistory();
    const [linkData, setData] = useState<ProfileType>();

    const getLink = () => {
        clientService.getLink().then(({ data }: AxiosResponse<ProfileResponseType>) => {
            setData(data.identityDataDTO);
            window.location.replace(data.identityDataDTO.linkToKyc);
        });
    };

    return (
        <div className="profile-document-wrapper">
            <div>
                <img src={DeclinedIcon} className="info-icon-size" />
            </div>
            <div className="profile-info">
                <div className="primary-text">Identity Verification</div>
                <div className="secondary-text">
                    You must verify your identity. Please follow this link to secure website and provide proof of address.
                </div>
            </div>
            <div></div>
            <div className="btn-container">
                {
                    <Button className="btn sign-in-btn" onClick={() => getLink()}>
                        Verify
                    </Button>
                }
            </div>
        </div>
    );
};

export default ProfileId;
