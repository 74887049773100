import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import usePrepareLink from '../../../hooks/use-prepare-link';
import { RouteModalPropsType } from '../../../models/models';
import { AuthRoutes } from '../../../routes/routes';
import authService from './../../../service/authService';
import ForgotPasswordView from './forgot-password-view';
import { useTranslation } from 'react-i18next';

export type ForgotPasswordFormType = {
    email: string;
};

const ForgotPassword: React.FC<RouteModalPropsType> = ({ isOpened, onClose }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        trigger,
    } = useForm<ForgotPasswordFormType>();

    const forgotMessage = usePrepareLink({
        to: AuthRoutes.PROCESS_SUCCESS,
        isRelativePath: true,
    });

    const handleForgotPassword = async (data: ForgotPasswordFormType) => {
        setLoading(true);
        authService.forgotPassword(data).then(onSucces, onError);
        function onSucces() {
            history.replace({
                ...forgotMessage,
                state: {
                    message: t('auth.forgotMessage', { email: data.email }),
                },
            });
        }

        function onError() {
            setLoading(false);
            setError('email', {
                type: 'manual',
                message: t('auth.emailNotFound'),
            });
        }
    };

    return isOpened ? (
        <ForgotPasswordView
            handleClose={onClose}
            handleForgotPassword={handleSubmit(handleForgotPassword)}
            register={register}
            errors={errors}
            loading={loading}
            isOpened={isOpened}
            trigger={trigger}
        />
    ) : null;
};

export default ForgotPassword;
