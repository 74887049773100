import React from 'react';
import { useUser } from '../context/user-context';
import { Card } from 'react-bootstrap';
import clientService from './../service/clientService';
import { RightArrowIcon } from '../assets/images';

const TermsCondition: React.FC = () => {
    const { user, loadUser } = useUser();

    const acceptTerms = () => {
        clientService.acceptTerms().then(() => {
            loadUser();
        });
    };

    return user && !user.isTermsAccepted ? (
        <div className="terms-contaier">
            <Card className="terms-card">
                <Card.Body>
                    <span className="terms-text">
                        <>
                            To continue with dashboard you must agree with our {'\u00A0'}
                            <a href="/docs/GBTAC.pdf" className="termsLink" target="_blank">
                                Terms & Conditions
                            </a>
                            {'\u00A0\u00A0'}and{'\u00A0\u00A0'}
                            <a href="/docs/CWPOPD.pdf" className="termsLink" target="_blank">
                                GDPR
                            </a>
                            <br />
                            Your contract is availible here{'\u00A0'}
                            <img src={RightArrowIcon} />
                            {'\u00A0'}
                            <a href="/docs/Agreement_on_Administration_of_Cryptocurrencies.pdf" className="termsLink" target="_blank">
                                EN /
                            </a>
                            <a href="/docs/Podmienky_a_ustanovenia.pdf" className="termsLink" target="_blank">
                                SK
                            </a>
                        </>
                    </span>
                    <button className="base-btn terms-btn" onClick={acceptTerms}>
                        Agree
                    </button>
                </Card.Body>
            </Card>
        </div>
    ) : null;
};

export default TermsCondition;
