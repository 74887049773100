import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../hooks/use-debounce';
import { ClientInvestmentItemType, ClientPortfolioType, ClientProfileType, EmpClientsResponseType } from '../../../models/api/dashboard-model';
import LoadingIndicator from './../../../components/loading-indicator';
import Paginator from './../../../components/paginator';
import employeeService from './../../../service/employeeService';
import ClientItem from './client-item';

const Clients: React.FC = () => {
    const { t } = useTranslation();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [clients, setClients] = useState<ClientProfileType[]>([]);
    const [pagination, setPagination] = useState<EmpClientsResponseType>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [investments, setInvestments] = useState<ClientInvestmentItemType[]>([]);
    const [portfolios, setPortfolios] = useState<ClientPortfolioType[]>();
    const [note, setNote] = useState<string>();

    const debounceNameSearch = useDebounce<string>(name.trim(), 600);
    const debounceEmailSearch = useDebounce<string>(email.trim(), 600);

    const loadClients = (name: string, email: string, page: number) => {
        setLoading(true);
        employeeService
            .getClients(name, email, { page })
            .then((res) => {
                setClients(res.data.clients.content);
                setPagination(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadClients(debounceNameSearch, debounceEmailSearch, pageIndex);
    }, [pageIndex, debounceNameSearch, debounceEmailSearch]);

    const loadUserPortfolios = (id: number) => {
        setLoading(true);
        setPortfolios(undefined);
        setNote(undefined);
        employeeService
            .getClientPortfolios(id)
            .then((res) => {
                setPortfolios(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const loadInvestments = (userId: number) => {
        setLoading(true);
        employeeService
            .getClientInvestments(userId, { size: 10 })
            .then((res) => setInvestments(res.data.content))
            .finally(() => {
                setLoading(false);
            });
    };

    const updateNote = (username: string) => {
        if (note)
            employeeService.editNote({ employeeNotes: note, username }).then((res) => {
                setNote('');
                const clientList = clients.map((item) => {
                    if (item.username === username) return res.data.userNotesDTO;
                    return item;
                });
                setClients(clientList);
            });
    };

    return (
        <>
            <div className="affiliate-grid-container client-container">
                <div className="search-wrapper">
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            {t('common.nameSearch')}
                        </label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            Email Search
                        </label>
                    </div>
                </div>
                <Accordion defaultActiveKey="0">
                    {clients?.map((i, index) => (
                        <ClientItem
                            key={index}
                            eventKey={`${i.id}`}
                            item={i}
                            portfolios={portfolios}
                            note={note}
                            handleNoteChange={setNote}
                            loadPortfolios={loadUserPortfolios}
                            handleUpdeteNote={updateNote}
                            investments={investments}
                            loadInvestments={loadInvestments}
                        />
                    ))}
                </Accordion>
                <Paginator
                    pageIndex={pageIndex}
                    dataLength={pagination?.clients.totalElements}
                    onPageChanged={(newPage: number) => {
                        setPageIndex(newPage);
                    }}
                    pageSize={20}
                />
                <LoadingIndicator visible={loading} />
                {/* <div><UserCountWidget /></div> */}
            </div>
        </>
    );
};

export default Clients;
