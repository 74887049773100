import moment from 'moment';
import React, { useCallback, useState, useMemo } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowDown, InwardIcon, OutsideIcon, SlovakFlag } from '../../../../assets/images';
import { ProfitsResponseType } from '../../../../models/api/dashboard-model';
import { formatCurrency } from '../../../../utils/utils';

type PropsType = {
    data?: { [key: number]: number };
    type: WithdrawBonusWidgetEnum;
    isReferal?: boolean;
};

type MonthToggle = {
    onClick: (e: React.MouseEvent) => void;
};

const MonthToggle: React.FC<MonthToggle> = ({ children, onClick }) => (
    <span
        className="month-toggle"
        role="button"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children} {'  '}
        <img src={ArrowDown} alt="" className="income-icon" />
    </span>
);

export enum WithdrawBonusWidgetEnum {
    ACTUAL = 'ACTUAL',
    BONUS = 'INVBONUSEST',
}

const WithdrawBonusWidget: React.FC<PropsType> = ({ data, type }) => {
    const { t } = useTranslation();
    const [activeMonth, setActiveMonth] = useState<number>(new Date().getMonth() + 1);

    const valueByMonth = useMemo(() => {
        return data?.[+activeMonth];
    }, [data, activeMonth]);

    const maxAmount = useMemo(() => {
        if (!data) return 0;
        return Math.max(...Object.values(data));
    }, [data]);

    const getPercentFromAmount = useCallback(
        (value: number) => {
            return (value * 100) / maxAmount;
        },
        [maxAmount],
    );

    return (
        <div className="refferal refferal-container">
            <div className="refferal-info">
                <div className="info-wrapper">
                    <div className="info-text">
                        {type === WithdrawBonusWidgetEnum.BONUS ? 'Actual Total Bonus profits' : 'Actual Total Active trading'}
                    </div>
                    <div className="info-percent">{formatCurrency(valueByMonth)}</div>
                    <div className="info-text">{}</div>
                </div>

                <div className="mt-2">
                    <Dropdown onSelect={(eventKey: any) => setActiveMonth(eventKey as number)} className="income-dropdown">
                        <Dropdown.Toggle as={MonthToggle} id="dropdown-custom-components">
                            Month: {moment(+activeMonth, 'M').format('MMMM')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {data &&
                                Object.keys(data).map((month) => (
                                    <Dropdown.Item key={month} eventKey={month}>
                                        {moment(month, 'M').format('MMMM')}
                                    </Dropdown.Item>
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="refferal-statistics">
                <div className="statistics-percentage"></div>
                <div className="statistic-item-list">
                    {data &&
                        Object.entries(data)
                            .reverse()
                            .map(([date, value]) => (
                                <OverlayTrigger
                                    key={date}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`id-d`} placement="top">
                                            {formatCurrency(value)}
                                        </Tooltip>
                                    }
                                >
                                    <div className={`statistic-item ${activeMonth == +date ? 'active' : ''} `}>
                                        <div className="item-month">{moment(date, 'M').format('MMM')}</div>
                                        <div className={`item-progress`}>
                                            <div
                                                className={`progress-view  ${type === WithdrawBonusWidgetEnum.BONUS ? 'invest' : 'payout'}`}
                                                style={{ width: `${getPercentFromAmount(value)}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            ))}
                </div>
            </div>
        </div>
    );
};
export default WithdrawBonusWidget;
