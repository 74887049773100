import React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SeifLogo } from '../../assets/images';
import { useFormRules } from '../../hooks/use-form-rules';
import { UserProfileType } from '../../models/api/auth-models';
import { RouteModalPropsType } from '../../models/models';
import BaseButton from '../common/base-button';
import BaseInput from '../common/base-input';
import DatePicker from '../datePicker/datePicker';

type PropsType = RouteModalPropsType & {
    handleSend: (data: ConsoleBalanceFormType) => void;
    client?: UserProfileType;
};

export type ConsoleBalanceFormType = {
    amount: number;
    dateTime: string;
};

const ConsoleBalanceModal: React.FC<PropsType> = ({ isOpened, onClose, handleSend }) => {
    const { t } = useTranslation();
    const { requiredTrimed } = useFormRules();

    const {
        register,
        formState: { errors },
        trigger,
        setValue,
        getValues,
    } = useForm();

    const handleClick = () => {
        const data = getValues();
        handleSend(data as ConsoleBalanceFormType);
    };

    return (
        <>
            <Modal show={isOpened} size="sm" onHide={onClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <div>
                            <BaseInput {...register('amount')} errors={errors} type="number" label={t('auth.amount')} trigger={trigger} />
                            <DatePicker setValue={setValue} maxDate={new Date()} name="date">
                                <BaseInput
                                    {...register('dateTime', { ...requiredTrimed })}
                                    errors={errors}
                                    trigger={trigger}
                                    type="text"
                                    maxLength={10}
                                    label={t('auth.date')}
                                />
                            </DatePicker>

                            <BaseButton onClick={handleClick} className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.send')}
                            </BaseButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConsoleBalanceModal;
