import React, { useState } from 'react';
import { WorldMapImage } from '../../../assets/images';
import { Controller, useForm } from 'react-hook-form';
import { ContactFormType } from '../../../models/models';
import clientService from './../../../service/clientService';
import { useToasts } from 'react-toast-notifications';
import { Trans, useTranslation } from 'react-i18next';
import { useFormRules } from './../../../hooks/use-form-rules';
import { Container, Row, Col } from 'react-bootstrap';

const ContactUs: React.FC = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm<ContactFormType>();

    const { formRules } = useFormRules();

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSend = (data: ContactFormType) => {
        setLoading(true);
        clientService
            .sendContactForm(data)
            .then(() => {
                addToast(t('toast.messageSended'));
            })
            .finally(() => {
                setLoading(false);
                reset();
                setValue('comment', '');
            });
    };

    return (
        <>
            <section id={'contact'} className="contact-us-bg">
                <Container>
                    <Row>
                        <Col>
                            <div className="row justify-content-evenly mt-5">
                                <div className="contact-section mb-5">
                                    <h1 className="contact-title">{t('landing.contact.contact')}</h1>

                                    <p className="mb-4 adress-description">
                                        <Trans i18nKey={'landing.contact.contactTitle'} />
                                    </p>
                                    <h3 className="adress-title">{t('landing.contact.address')}</h3>
                                    <p className="adress-description m-0"> Seif Service LLC</p>
                                    <p className="mb-4 adress-description">
                                        The Financial Services Centre Stoney Ground, <br />
                                        Kingstown St. Vincent and the Grenadines
                                    </p>
                                    <h3 className="adress-title">{t('landing.contact.mailingAddress')}</h3>
                                    <p className="mb-4 adress-description">
                                        P.O. Box 1823
                                        <br />
                                        Kingstown, VC0100
                                        <br />
                                        St. Vincent and the Grenadines
                                    </p>
                                    <h3 className="email-title">Email</h3>
                                    <p className="mb-5 email-description">support@seifgroup.io</p>
                                    <img src={WorldMapImage} className="world-map-mobile" alt="" />
                                </div>
                            </div>
                        </Col>
                        <Col xs>
                            <form className="contact-form" onSubmit={handleSubmit(handleSend)}>
                                <Controller
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.name ? 'text-danger' : 'text-white'} form-label-title`}>Name:</label>
                                            <input {...field} disabled={loading} className="contact-input" type="text" />
                                        </div>
                                    )}
                                    control={control}
                                    name="name"
                                />
                                <Controller
                                    rules={{
                                        required: true,
                                        ...formRules.email,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.email ? 'text-danger' : 'text-white'} form-label-title`}>Email:</label>
                                            <input {...field} disabled={loading} className="contact-input" type="text" />
                                        </div>
                                    )}
                                    control={control}
                                    name="email"
                                />
                                <Controller
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.subject ? 'text-danger' : 'text-white'} pr-2  form-label-title`}>
                                                Subject:
                                            </label>
                                            <input {...field} disabled={loading} className="contact-input" type="text" />
                                        </div>
                                    )}
                                    control={control}
                                    name="subject"
                                />
                                <Controller
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.comment ? 'text-danger' : 'text-white'} pr-2  form-label-title`}>
                                                Comment:
                                            </label>
                                            <textarea {...field} disabled={loading} className="contact-input-text-area"></textarea>
                                        </div>
                                    )}
                                    control={control}
                                    name="comment"
                                />
                                <div className="px-2 py-2">
                                    <button disabled={loading} className="contact-form-button btn" type="submit">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
                {/* <div className="container pt-5">
                    <div className="row justify-content-evenly">
                        <div className="col-4">
                            <div className="contact-section mb-5">
                                <h1 className="mb-5">Contact</h1>
                                <br />
                                <h3>Adress</h3>
                                <p className="mb-4">
                                    Nwms Center 31 Southampton Row, <br />
                                    Office 4.19, 4th Floor, London, England, WC1B 5HJ
                                </p>
                                <h3>Email</h3>
                                <p className="mb-5">support@seifgroup.io</p>
                                <img src={WorldMapImage} alt="" />
                            </div>
                        </div>
                        <div className="col-4">
                            <form className="contact-form" onSubmit={handleSubmit(handleSend)}>
                                <Controller
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.name ? 'text-danger' : 'text-white'} form-label-title`}>Name:</label>
                                            <input {...field} disabled={loading} className="contact-input" type="text" />
                                        </div>
                                    )}
                                    control={control}
                                    name="name"
                                />
                                <Controller
                                    rules={{
                                        required: true,
                                        ...formRules.email,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.email ? 'text-danger' : 'text-white'} form-label-title`}>Email:</label>
                                            <input {...field} disabled={loading} className="contact-input" type="text" />
                                        </div>
                                    )}
                                    control={control}
                                    name="email"
                                />
                                <Controller
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.subject ? 'text-danger' : 'text-white'} pr-2  form-label-title`}>
                                                Subject:
                                            </label>
                                            <input {...field} disabled={loading} className="contact-input" type="text" />
                                        </div>
                                    )}
                                    control={control}
                                    name="subject"
                                />
                                <Controller
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <div className="d-flex align-items-center py-3 px-3">
                                            <label className={`${errors.comment ? 'text-danger' : 'text-white'} pr-2  form-label-title`}>
                                                Comment:
                                            </label>
                                            <textarea {...field} disabled={loading} className="contact-input-text-area"></textarea>
                                        </div>
                                    )}
                                    control={control}
                                    name="comment"
                                />

                                <div className="px-2 py-2">
                                    <button disabled={loading} className="contact-form-button btn" type="submit">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    );
};

export default ContactUs;
