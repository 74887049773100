import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SeifLogo } from '../../../assets/images';
import BaseButton from '../../../components/common/base-button';
import BaseInput from '../../../components/common/base-input';
import { LoginDataType } from '../../../models/api/auth-models';
import { BaseFormType } from '../../../models/models';
import { AuthRoutes } from '../../../routes/routes';
import LoadingIndicator from '../../../components/loading-indicator';
import { useFormRules } from '../../../hooks/use-form-rules';
import { useTranslation } from 'react-i18next';
import PasswordInput from './../../../components/common/password-input';

type PropsType = BaseFormType<LoginDataType> & {
    handleLogin: () => void;
    loading: boolean;
    handleClose: () => void;
    isOpened: boolean;
};

const LoginView: React.FC<PropsType> = ({ handleLogin, register, errors, trigger, loading, handleClose, isOpened }) => {
    const { requiredTrimed, formRules } = useFormRules();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>
            <LoadingIndicator visible={loading} />
            <Modal show={isOpened} size="sm" onHide={handleClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <form onSubmit={handleLogin}>
                            <BaseInput
                                {...register('userName', { ...requiredTrimed })}
                                errors={errors}
                                type="text"
                                label={t('auth.username')}
                                trigger={trigger}
                            />
                            <PasswordInput
                                {...register('password', { ...requiredTrimed, ...formRules.minLengthX(6) })}
                                errors={errors}
                                paswordVisible={showPassword}
                                handlePasswordVisibility={() => setShowPassword(!showPassword)}
                                label={t('auth.password')}
                                trigger={trigger}
                            />
                            <div className="singin-text-btns">
                                {/* <Link to={AuthRoutes.REGISTRATION} replace>
                                    {t('auth.not-have-acc')}
                                </Link> */}
                                <div> </div>
                                <Link to={AuthRoutes.FORGOT_PASSWORD} replace>
                                    {t('auth.forgot-password')}
                                </Link>
                            </div>

                            <BaseButton type="submit" className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.login')}
                            </BaseButton>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LoginView;
