import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../utils/utils';

type PropsType = {
    total?: number;
    agio?: number;
    totalBonus?: number;
    referralTotal?: {
        FIRST: number;
        NETWORK: number;
        SECOND: number;
    };
    lastPercent?: number;
};

const ReferralWidget: React.FC<PropsType> = ({ total, agio, totalBonus, referralTotal, lastPercent }) => {
    const { t } = useTranslation();
    return (
        <div className="profit-container">
            <div className="profit-amount">{formatCurrency(total)}</div>
            <div className="profit-text">{`Total: ${formatCurrency(agio) ?? 0}`}</div>
            <div className="profit-count">
                <div className="percentage">
                    {referralTotal ? '7%' : '0%'}
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                </div>
                <div className="people">{formatCurrency(referralTotal?.FIRST)}</div>
            </div>

            <div className="profit-count">
                <div className="percentage">
                    {referralTotal ? '3%' : '0%'}
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                </div>
                <div className="people">{formatCurrency(referralTotal?.SECOND)}</div>
            </div>

            <div className="profit-count">
                <div className="percentage">
                    {lastPercent ?? 0}%
                    <FontAwesomeIcon icon={faMale} className="p-icon" />
                </div>
                <div className="people">{formatCurrency(referralTotal?.NETWORK ?? totalBonus)}</div>
            </div>
        </div>
    );
};

export default ReferralWidget;
