export enum RolesEnum {
    USER = 'USER',
    ADMIN = 'ADMIN',
    EMPLOYEE = 'EMPLOYEE',
    SUPERVISOR = 'SUPERVISOR',
    NETWORKER = 'NETWORKER',
}

export enum HistoryEnum {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
}

export enum LangEnum {
    EN = 'en',
    SK = 'sk',
    CZ = 'cz',
    PL = 'pl',
    GR = 'gr',
}

export enum BalikEnum {
    STARTUP = 'STARTUP',
    ESTABLISHED = 'ESTABLISHED',
    ACTIVE = 'ACTIVE',
}

export enum HistoryDataEnum {
    BALANCE = 'BALANCE',
    STARTUP = 'STARTUP',
    ESTABLISHED = 'ESTABLISHED',
    ACTIVE = 'ACTIVE',
}

export enum HistoryProfitEnum {
    TOTAL = 'totalBalanceHistory',
    ACTIVE = 'activeBalanceHistory',
    BONUS = 'bonusBalanceHistory',
}

export enum PayoutStatusEnum {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
}

export enum IdentityDataStatusEnum {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    REQUIRED = 'REQUIRED',
}

export enum ChartDateEnum {
    ONE_MONTH = 'ONE_MONTH',
    SIX_MONTH = 'SIX_MONTH',
    YEAR = 'YEAR',
    ALL = 'ALL',
}

export enum InboxTypeEnum {
    MARKETING = 'MARKETING',
    NEWS = 'NEWS',
    INFORMATION = 'INFORMATION',
    WEBINAR = 'WEBINAR',
}

export enum InboxTypeDrowpdownEnum {
    ALL = 'ALL',
    MARKETING = 'MARKETING',
    NEWS = 'NEWS',
    INFORMATION = 'INFORMATION',
    WEBINAR = 'WEBINAR',
}
