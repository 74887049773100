import React from 'react';
import { useUser } from '../../../../context/user-context';
import { Card, Modal } from 'react-bootstrap';
import clientService from '../../../../service/clientService';
import { RightArrowIcon } from '../../../../assets/images';
import { WithdrawalFormType } from '../withdrawal';
import { BaseFormType } from '../../../../models/models';

type PropsType = {
    onClose: () => void;
    isOpened: boolean;
};

const KycMessage: React.FC<PropsType> = ({ onClose, isOpened }) => {
    const { user, setUser } = useUser();

    return (
        <>
            <Modal show={isOpened} size="sm" onHide={onClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <span className="text-muted">
                            <>Withdrawals are disabled. Please verify your identity in profile.</>
                        </span>
                        <button className="auth-sign-in-btn btn-center mt-4" onClick={onClose}>
                            Agree
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default KycMessage;
