import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { SeifLogo } from '../../../assets/images';
import { RouteModalPropsType } from '../../../models/models';
import Routes from '../../../routes/routes';

const MessageModal: React.FC<RouteModalPropsType> = ({ isOpened, onClose }) => {
    const history = useHistory();
    const location = useLocation<{ message: string }>();

    const message = location.state?.message;

    useEffect(() => {
        if (isOpened && !message) history.replace(Routes.ROOT);
    }, [history, message, isOpened]);

    return (
        <Modal show={isOpened} onHide={onClose} centered>
            <Modal.Body>
                <div className="process-message-container">
                    <div className="auth-logo">
                        <img src={SeifLogo} alt="" />
                    </div>
                    <div className="process-message-text">
                        <span>{message}</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MessageModal;
