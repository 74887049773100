import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useRoles } from '../../hooks/use-roles';
import { RolesEnum } from '../../models/enums';
import Routes from '../routes';

type RoleRouteType = RouteProps & {
    role: RolesEnum;
};

const RoleRoute: React.FC<RoleRouteType> = (props) => {
    const { hasRole } = useRoles();
    if (hasRole(props.role)) {
        return <Route {...props} />;
    } else {
        return <Redirect to={Routes.ROOT} />;
    }
};

export default RoleRoute;
