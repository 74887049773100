import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router';
import { RolesEnum } from '../../models/enums';
import { SidebarLinkItem } from '../../models/models';
import { ClientDashboardRoutes } from '../../routes/routes';
import BaseHeader from './../../components/header/base-header';
import BaseSidebar from './../../components/sidebar/base-sidebar';
import RoleRoute from './../../routes/guard/role-route';
import Affiliate from './affiliate/affiliate';
import Dashboard from './dashboard/dashboard';
import Invest from './invest/invest';
import Refferals from './refferals/refferals';
import Reinvest from './reinvest/reinvest';
import Withdrawal from './withdrawal/withdrawal';
import Documents from './documents/documents';
import Profile from './profile/profile';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Button, Offcanvas } from 'react-bootstrap';
import { HamburgerCollapse, HamburgerToggle } from '../../assets/images';
import Inbox from './inbox/Inbox';
import { useUser } from '../../context/user-context';

const Client: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useUser();

    const userLinks: SidebarLinkItem[] = useMemo(() => {
        return [
            {
                to: ClientDashboardRoutes.PROFILE,
                icon: '',
                title: t('navlinks.profile'),
            },
            {
                to: ClientDashboardRoutes.DASHBOARD,
                icon: '',
                title: t('navlinks.dashboard'),
            },
            {
                to: ClientDashboardRoutes.AFFILIATE,
                icon: '',
                title: t('navlinks.affiliate'),
            },
            {
                to: ClientDashboardRoutes.DOCUMENTS,
                icon: '',
                title: t('navlinks.documents'),
            },
            {
                to: ClientDashboardRoutes.REFFERALS,
                icon: '',
                title: t('navlinks.refferals'),
            },
            {
                to: ClientDashboardRoutes.INVEST,
                icon: '',
                title: t('navlinks.invest'),
            },
        ];
    }, [t]);

    return (
        <>
            <div className="container-fluid">
                <div className="row min-vh-100 flex-column flex-md-row">
                    <aside className="col-12 col-md-2 p-0 flex-shrink-1 w-auto bg-aside">
                        <BaseSidebar links={userLinks} />
                    </aside>
                    <main className="col bg-faded py-3 flex-grow-1 bg-dashboard">
                        <BaseHeader />
                        <Switch>
                            <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.DASHBOARD} component={Dashboard} />
                            <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.AFFILIATE} component={Affiliate} />
                            <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.DOCUMENTS} component={Documents} />
                            <RoleRoute role={RolesEnum.USER} path={ClientDashboardRoutes.REFFERALS} component={Refferals} />
                            <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.INVEST} component={Invest} />
                            <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.REINVEST} component={Reinvest} />
                            <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.WITHDRAWAL} component={Withdrawal} />
                            <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.PROFILE} component={Profile} />
                            {user?.isInvested && <RoleRoute role={RolesEnum.USER} exact path={ClientDashboardRoutes.INBOX} component={Inbox} />}
                            <Redirect to={ClientDashboardRoutes.DASHBOARD} />
                        </Switch>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Client;
