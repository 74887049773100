import React from 'react';
import { AboutUsImage } from '../../../assets/images';
import Fade from 'react-reveal/Fade';
import StarfieldAnimation from 'react-starfield-animation';
import { Trans, useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';

const AboutUs: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="about-us-bg" id={'about'}>
                <StarfieldAnimation
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                />
                <Container className="about-us-container">
                    <Fade left>
                        <Row>
                            <Col>
                                <h1 className="about-us-title pb-4">
                                    <Trans i18nKey="landing.about.title" />
                                </h1>
                                <p className="about-desc">
                                    <Trans i18nKey={'landing.about.description'} />
                                </p>
                            </Col>
                            <Col md="auto"></Col>
                            <Col>
                                <img src={AboutUsImage} className="about-us-img" alt="AboutUs" />
                            </Col>
                        </Row>
                        <Row className="justify-content-md-left">
                            <Col md="auto">
                                <h2 className="about-us-second-title mb-4">
                                    <Trans i18nKey={'landing.about.investment'} />
                                </h2>
                                <h4 className="about-us-second-desc">
                                    <Trans i18nKey={'landing.about.freeYourtime'} />
                                </h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md="auto" className="about-us-bottom-text">
                                <Trans i18nKey={'landing.about.weHelp'} />
                            </Col>
                        </Row>
                    </Fade>
                </Container>
                {/* <div className="container pt-5">
                    <Fade left>
                        <h1 className="about-us-title pb-4">
                            <Trans i18nKey="landing.about.title" />
                        </h1>

                        <div className="row justify-content-between">
                            <div className="col-4">
                                <p className="about-desc">
                                    <Trans i18nKey={'landing.about.description'} />
                                </p>
                            </div>
                            <div className="col-4">
                                <img src={AboutUsImage} className="about-us-img" alt="AboutUs" />
                            </div>
                        </div>
                        <div>
                            <h2 className="about-us-second-title mb-4">
                                <Trans i18nKey={'landing.about.investment'} />
                            </h2>
                            <h4 className="about-us-second-desc">
                                <Trans i18nKey={'landing.about.freeYourtime'} />
                            </h4>
                        </div>
                        <div className="text-center about-us-bottom-text">
                            <Trans i18nKey={'landing.about.weHelp'} />
                        </div>
                    </Fade>
                </div> */}
            </section>
        </>
    );
};

export default AboutUs;
