import React, { useEffect, useState, useMemo } from 'react';
import clientService from '../../../service/clientService';
import { AffiliateType, AffiliatesHeadType, ClientSimplified, SimplifiedAffiliatesType } from '../../../models/api/dashboard-model';
import { useUser } from '../../../context/user-context';
import AffiliateView from '../../client/affiliate/affiliate-view';
import { useDebounce } from '../../../hooks/use-debounce';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Col, Row, useAccordionButton } from 'react-bootstrap';

const EmployeeAffiliate: React.FC = () => {
    const { user } = useUser();

    const [head, setHead] = useState<AffiliatesHeadType>();
    const [affiliates, setAffiliates] = useState<AffiliateType[]>([]);
    const [userAffiliates, setUserAffiliates] = useState<AffiliateType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [clientsSimplified, setClientsSimplified] = useState<SimplifiedAffiliatesType[]>([]);
    const [name, setName] = useState<string>('');
    const [parentList, setParentList] = useState<AffiliateType[]>([]);

    const debounceSearch = useDebounce<string>(name.trim(), 600);

    const userOptions = useMemo(() => {
        return clientsSimplified.map((item) => {
            return {
                id: item.id,
                label: item.fullName,
            };
        });
    }, [clientsSimplified]);

    const updateAffiliate = (id: number, item: AffiliateType, items: AffiliateType[]): AffiliateType => {
        if (item.id === id) return { ...item, items };
        if (!item?.items) return item;
        const affiliateItems = item.items.map((i) => updateAffiliate(id, i, items));
        return { ...item, items: affiliateItems };
    };

    const getAffiliates = (id: number, line: number) => {
        setLoading(true);
        clientService
            .getAffiliates(id, line)
            .then((res) => {
                const data = affiliates?.map((item) => updateAffiliate(id, item, res.data.affiliates));
                setAffiliates(data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getSearchAffiliates = (id: number, line: number) => {
        setLoading(true);
        clientService
            .getAffiliates(id, line)
            .then((res) => {
                const data = userAffiliates?.map((item) => updateAffiliate(id, item, res.data.affiliates));
                setUserAffiliates(data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (debounceSearch.trim().length > 0) {
            setSearchLoading(true);
            clientService
                .getSimplifiedAffiliates(debounceSearch)
                .then((res) => {
                    setClientsSimplified(res.data.affiliates);
                })
                .finally(() => {
                    setSearchLoading(false);
                });
        } else {
            setParentList([]);
            setUserAffiliates([]);
        }
    }, [debounceSearch]);

    useEffect(() => {
        setLoading(true);
        clientService.getAffiliatesHead().then((res) => {
            setHead(res.data);
        });
        user &&
            clientService
                .getAffiliates(user.id, 0)
                .then((res) => {
                    setAffiliates(res.data.affiliates);
                })
                .finally(() => {
                    setLoading(false);
                });
    }, [user]);

    return (
        <>
            <Row className="mb-2">
                <Col xs={12} md={12} lg={8} className="affiliate-third-order">
                    <div className="console-autocomplete">
                        <label htmlFor="exampleDataList" className="form-label"></label>
                        <AsyncTypeahead
                            isLoading={searchLoading}
                            className="search-typehead"
                            id="searchClient"
                            placeholder="Search client"
                            onSearch={setName}
                            onChange={(selected) => {
                                if (selected.length > 0) {
                                    clientService
                                        .getAffiliates(user!.id, 0, selected[0].id)
                                        .then(({ data }) => {
                                            const currentUser = data.affiliates[data.affiliates.length - 1];
                                            setParentList(data.affiliates.filter((i) => i.id !== currentUser.id));
                                            setUserAffiliates([currentUser]);
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        });
                                } else {
                                    setParentList([]);
                                    setUserAffiliates([]);
                                }
                            }}
                            options={userOptions}
                        />
                    </div>
                </Col>
            </Row>
            <AffiliateView
                head={head}
                affiliates={affiliates}
                loadAffiliates={getAffiliates}
                loadSearchAffiliates={getSearchAffiliates}
                parentAffiliates={parentList}
                userAffiliates={userAffiliates}
                loading={loading}
            />
        </>
    );
};

export default EmployeeAffiliate;
