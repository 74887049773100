import React from 'react';
import { IconPreview, IconDownload } from '../../../../assets/images';
import clientService from '../../../../service/clientService';

type PropsType = {
    name: string;
    urlPath: string;
    color: string;
};

const DocumentItem: React.FC<PropsType> = ({ name, urlPath, color }) => {
    const showDoc = () => {
        clientService.downloadFile(urlPath).then(async (res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            const blobUrl = window.URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
            // link.href = blobUrl;
            // link.target = '_blank';
            // link.click();
            // link.remove();
            // URL.revokeObjectURL(blobUrl);
        });
    };

    const downloadDoc = () => {
        clientService.downloadFile(urlPath).then(async (res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            const blobUrl = window.URL.createObjectURL(blob);
            link.href = blobUrl;
            link.download = name + new Date() + '.pdf';
            link.click();
            link.remove();
            URL.revokeObjectURL(blobUrl);
        });
    };

    return (
        <div className="document-item">
            <div className="item-divider" style={{ backgroundColor: color }}></div>
            <div className="item-container">
                <div>
                    <div className="item-title">{name}</div>
                </div>
                <div className="item-btn-group">
                    <a href={`/${urlPath}`} target="_blank" rel="noreferrer">
                        <img src={IconPreview} width={30} alt="" role="button" />
                    </a>
                    <img src={IconDownload} alt="" width={30} role="button" onClick={downloadDoc} />
                </div>
            </div>
        </div>
    );
};

export default DocumentItem;
