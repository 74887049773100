import moment from 'moment';
import React from 'react';
import { Accordion, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ClientStatisticPortfolioType, InvestmentHistory } from '../../../../models/api/dashboard-model';
import { formatCurrency } from './../../../../utils/utils';

type PropsType = {
    eventKey: string;
    item: InvestmentHistory;
    activeInvestDetail: ClientStatisticPortfolioType[];
    loadInvestDetail: (id: number) => void;
};

const InvestHistoryItem: React.FC<PropsType> = ({ eventKey, item, loadInvestDetail, activeInvestDetail }) => {
    const { t } = useTranslation();

    return (
        <ContextAwareToggle eventKey={eventKey} callback={() => loadInvestDetail(item.id)}>
            <>
                <div className="hsitory-item">
                    <div className="history-item-left">{formatCurrency(item.amount)}</div>
                    <div className="history-item-right">{moment(item.createdAt).format('DD-MM-yyyy')}</div>
                </div>
                <Accordion.Collapse eventKey={eventKey}>
                    <>
                        {activeInvestDetail.map((item, index) => (
                            <div className="hsitory-item" key={index}>
                                <div className="history-item-secondary">{item.position.type}</div>
                                <div className="history-item-secondary">{formatCurrency(item.initialAmount)}</div>
                            </div>
                        ))}
                    </>
                </Accordion.Collapse>
                <div className="divider"></div>
            </>
        </ContextAwareToggle>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback, children }: ContextAwareToggleProps) {
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    return <div onClick={decoratedOnClick}>{children}</div>;
}

export default InvestHistoryItem;
