import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatPercent } from '../../../../utils/utils';

type PropsType = {
    annual: {
        [key: string]: number;
    };
};

const InvestAnnualStatistic: React.FC<PropsType> = ({ annual }) => {
    const { t } = useTranslation();

    const maxInvested = useMemo(() => {
        return Math.max(...Object.values(annual));
    }, [annual]);

    const getPercent = (value: number) => {
        return (value * 100) / maxInvested + '%';
    };

    return (
        <div className="annual">
            <div className="annual-title">{t('invest.annualInvest')}</div>

            <div className="annual-item-list">
                {Object.entries(annual).map(([year, value]) => (
                    <div className="annual-item" key={year}>
                        <div className="item-year">{year}</div>
                        <div className="item-progress">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`id-d`} placement="top">
                                        {formatCurrency(value)}
                                    </Tooltip>
                                }
                            >
                                <div className="progress-view" style={{ width: getPercent(value) }}></div>
                            </OverlayTrigger>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InvestAnnualStatistic;
