import { AxiosResponse } from 'axios';
import moment from 'moment';
import {
    AdminClientsResponseType,
    AdminStatisticResposneType,
    AdminUpdateClientRequestType,
    ClientInvestmentResponseType,
    ClientSimplifiedResponse,
    ClientsPaginateResponseType,
    ClientStatisticPortfolioType,
    ClientStatisticResponseType,
    ClientTotalResponseType,
    CloseTradeHistoryResponseType,
    CloseTradeRequestType,
    DeleteNoteRequest,
    EmployeesResponseType,
    EmployeeStatisticResponseType,
    EmployeeStatisticTotalItem,
    EmulatePaymentRequest,
    ForcePayoutRequestType,
    InboxRequestType,
    PagebleReqeustType,
    PayoutRequestResponseType,
    ReferralStatisticResponseType,
    ReferralTotalResponseType,
    RegisteredUsersRequestType,
    RegisteredUsersResponseType,
    SaleInvestmentResponseType,
    SaleStatisticResponseType,
    SaleTotalResponseType,
    WithdrawHistoryResponseType,
    WithdrawStatisticResponseType,
    WithdrawTotalResponseType,
} from '../models/api/dashboard-model';
import axiosInstance from './axiosConfig';

const adminService = {
    inviteEmployee: (email: string): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('admin/invite-employee', { to: email });
    },
    closeTrade: (gains: CloseTradeRequestType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/close-trade', { gains });
    },
    tradeHistroy: (): Promise<AxiosResponse<CloseTradeHistoryResponseType>> => {
        return axiosInstance.get('/admin/trade-history');
    },
    getEmployees: (name: string, email: string, pageable: PagebleReqeustType): Promise<AxiosResponse<EmployeesResponseType>> => {
        return axiosInstance.get('/admin/get-employees', { params: { name, email, ...pageable } });
    },
    getClients: (filter: string, email: string, pageable: PagebleReqeustType): Promise<AxiosResponse<AdminClientsResponseType>> => {
        return axiosInstance.get('/admin/get-clients', { params: { filter, email, ...pageable } });
    },
    disableUser: (userId: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/disable-user', null, { params: { userId } });
    },
    getStatistics: (): Promise<AxiosResponse<AdminStatisticResposneType>> => {
        return axiosInstance.get('/admin/get-statistics');
    },
    getPayoutRequests: (filter: string, email: string, page: number): Promise<AxiosResponse<PayoutRequestResponseType>> => {
        return axiosInstance.get('/admin/get-payout-requests', { params: { filter, email, page } });
    },
    payoutApproveRequest: (id: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/payout-approve', { payoutRequestId: id });
    },
    payoutDeclineRequest: (id: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/payout-decline', { payoutRequestId: id });
    },
    getUsers: (pageIndex: number, parentName = '', name = '', email = ''): Promise<AxiosResponse<ClientsPaginateResponseType>> => {
        const data = { parentName, name, email, page: pageIndex };
        return axiosInstance.get(`/admin/get-users`, {
            params: data,
        });
    },
    getUsersSimplified: (): Promise<AxiosResponse<ClientSimplifiedResponse>> => {
        return axiosInstance.get('/admin/get-users-simplified');
    },
    emulatePayment: (data: EmulatePaymentRequest): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/emulate-payment', data);
    },
    updateUser: (data: AdminUpdateClientRequestType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/update-user', data);
    },

    getSalesTotal: (countryCode: string, year: number): Promise<AxiosResponse<SaleTotalResponseType>> => {
        return axiosInstance.get('/admin/get-sales-totals', { params: { countryCode, year } });
    },
    getSalesStatistics: (filter: string, username: string, pageable: PagebleReqeustType): Promise<AxiosResponse<SaleStatisticResponseType>> => {
        return axiosInstance.get('/admin/get-sales-statistics', { params: { filter, username, ...pageable } });
    },
    getSalesInvestments: (employeeId: number, pageable: PagebleReqeustType): Promise<AxiosResponse<SaleInvestmentResponseType>> => {
        return axiosInstance.get('/admin/get-sales-investments', { params: { employeeId, ...pageable } });
    },
    getWithdrawTotal: (): Promise<AxiosResponse<WithdrawTotalResponseType>> => {
        return axiosInstance.get('/admin/get-withdrawal-totals');
    },
    getWithdrawStatistics: (
        name: string,
        dateFilter: string,
        pageable: PagebleReqeustType,
    ): Promise<AxiosResponse<WithdrawStatisticResponseType>> => {
        const date = dateFilter && moment(dateFilter).format('yyyy-MM-DD');
        return axiosInstance.get('/admin/get-withdrawal-statistics', { params: { name, date, ...pageable } });
    },
    getWithdrawHistories: (userId: number, dateFilter: string, pageable: PagebleReqeustType): Promise<AxiosResponse<WithdrawHistoryResponseType>> => {
        const date = dateFilter && moment(dateFilter).format('yyyy-MM-DD');
        return axiosInstance.get('/admin/get-withdrawal-histories', { params: { userId, date, ...pageable } });
    },
    getReferralTotal: (countryCode: string, year: number): Promise<AxiosResponse<ReferralTotalResponseType>> => {
        return axiosInstance.get('/admin/get-referrals-totals', { params: { countryCode, year } });
    },
    getReferralStatistics: (
        filter: string,
        username: string,
        pageable: PagebleReqeustType,
    ): Promise<AxiosResponse<ReferralStatisticResponseType>> => {
        const params = { ...pageable, username, filter };
        return axiosInstance.get('/admin/get-referral-statistics', { params });
    },
    getReferralInvestments: (networkerId: number, pageable: PagebleReqeustType): Promise<AxiosResponse<SaleInvestmentResponseType>> => {
        return axiosInstance.get('/admin/get-referral-investments', { params: { networkerId, ...pageable } });
    },
    getClientTotal: (countryCode: string, year: number): Promise<AxiosResponse<ClientTotalResponseType>> => {
        return axiosInstance.get('/admin/get-clients-totals', { params: { countryCode, year } });
    },
    getClientPortfolios: (paymentId: number): Promise<AxiosResponse<ClientStatisticPortfolioType[]>> => {
        return axiosInstance.get('/admin/get-clients-portfolios', { params: { paymentId } });
    },
    getClientStatistics: (
        name: string,
        email: string,
        parentName: string,
        username: string,
        pageable: PagebleReqeustType,
    ): Promise<AxiosResponse<ClientStatisticResponseType>> => {
        return axiosInstance.get(`/admin/get-clients-statistics`, { params: { parentName, email, name, username, withdrawDate: '', ...pageable } });
    },
    getClientInvestments: (userId: number, pageable: PagebleReqeustType): Promise<AxiosResponse<ClientInvestmentResponseType>> => {
        return axiosInstance.get('/admin/get-clients-investments', { params: { userId, ...pageable } });
    },
    getClientsSimplified: (filter: string): Promise<AxiosResponse<ClientSimplifiedResponse>> => {
        return axiosInstance.get('/admin/get-clients-simplified', { params: { filter } });
    },
    updateColor: (color: string, employeeId: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/set-color', { color, employeeId });
    },
    sendInbox: (data: InboxRequestType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('admin/send-inbox', data);
    },
    deleteUserNote: (data: DeleteNoteRequest): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/delete-user-notes', null, { params: data });
    },
    forcePayout: (data: ForcePayoutRequestType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/admin/force-payout', data);
    },
    loadRegisteredClients: (
        searchData: RegisteredUsersRequestType,
        pageable: PagebleReqeustType,
    ): Promise<AxiosResponse<RegisteredUsersResponseType>> => {
        const date = searchData.registrationDate && moment(searchData.registrationDate).format('yyyy-MM-DD');
        return axiosInstance.get('/admin/registered-users', { params: { ...searchData, registrationDate: date, ...pageable } });
    },
    loadEmployeeStatistics: (employeeId: number, monthNumber: number): Promise<AxiosResponse<EmployeeStatisticResponseType>> => {
        return axiosInstance.get('admin/get-employee-statistics', { params: { employeeId, monthNumber } });
    },
    getEmployeeStatisticsTotal: (): Promise<AxiosResponse<EmployeeStatisticTotalItem[]>> => {
        return axiosInstance.get('admin/get-employee-statistics-total');
    },
    getWithdrawCount: (): Promise<AxiosResponse<number>> => {
        return axiosInstance.get('admin/get-pending-requests-count');
    },
};

export default adminService;
