import { Dispatch, SetStateAction, useState } from 'react';

/**
 * https://stackoverflow.com/questions/60048276/alternative-to-custom-hook-call-inside-useeffect
 * @param key
 * @param initialValue
 */
export function useLocalStorage<T>(
    key: string,
    initialValue: T,
): { value: T; setValue: Dispatch<SetStateAction<T>>; clear: () => void; clearStore: () => void } {
    const getLocalStorageValue = (): T => {
        try {
            const currValue = window.localStorage.getItem(key);
            return currValue ? JSON.parse(currValue) : initialValue;
        } catch (e) {
            return initialValue;
        }
    };

    const [storedValue, setStoredValue] = useState<T>(getLocalStorageValue);

    const setValue: Dispatch<SetStateAction<T>> = (value: SetStateAction<T>) => {
        try {
            setStoredValue(value);
            const currValue: T = getLocalStorageValue();
            const newValue = value instanceof Function ? value(currValue) : value;
            window.localStorage.setItem(key, JSON.stringify(newValue));
        } catch (e) {}
    };

    const clear = () => {
        window.localStorage.removeItem(key);
        // setStoredValue(initialValue);
    };

    const clearStore = () => {
        window.localStorage.clear();
    };

    return { value: storedValue, setValue, clear, clearStore };
}
