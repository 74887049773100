import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { SeifLogo, WalletActiveIcon, WalletIcon } from '../../assets/images';
import { RolesEnum } from '../../models/enums';
import Routes, { ClientDashboardRoutes } from '../../routes/routes';
import { useRoles } from './../../hooks/use-roles';
import MobileLogo from '../../assets/images/mobile-logo.png';
import { Bell } from 'react-bootstrap-icons';
import { useNotification } from '../../context/notification-context';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { inboxColors } from '../../utils/utils';
import { useUser } from '../../context/user-context';

const BaseHeader: React.FC = ({}) => {
    const { hasRole } = useRoles();
    const { inboxAlerts } = useNotification();
    const history = useHistory();
    const { user } = useUser();

    const [isOverlay, setOverlay] = useState<boolean>(false);

    const withdrawMatch = useRouteMatch({
        path: ClientDashboardRoutes.WITHDRAWAL,
    });

    const dateNow = moment().format('DD/MM/yyyy');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (e: any) => {
        e.preventDefault();
    };

    const popover = useMemo(
        () => (
            <Popover>
                <Popover.Header as="h3">Notifications</Popover.Header>
                <Popover.Body>
                    <>
                        {inboxAlerts.map((alert) => (
                            <div
                                key={alert.id}
                                className="alert-item"
                                onClick={() => {
                                    setOverlay(!isOverlay);
                                    history.push(ClientDashboardRoutes.INBOX);
                                }}
                            >
                                <div className="alert-wrapper">
                                    <div
                                        className="alert-icon"
                                        style={{
                                            backgroundColor: inboxColors[alert.type],
                                        }}
                                    ></div>
                                    <div className="alert-subject">{alert.subject}</div>
                                </div>
                                <div className="alert-date">{moment(alert.createdAt).format('DD.MM.YYYY hh:mm')}</div>
                                <div className="alert-divider"></div>
                            </div>
                        ))}
                        {inboxAlerts.length === 0 && <div className="text-white">No more News</div>}
                    </>
                </Popover.Body>
            </Popover>
        ),
        [inboxAlerts, history, isOverlay],
    );

    return (
        <>
            <header className="navbar nav-header flex-md-nowrap p-0">
                <NavLink to={Routes.ROOT} replace className="navbar-brand col-md-3 col-lg-2 me-0 px-3">
                    <img src={SeifLogo} alt="" className="header-logo" />
                    <img src={MobileLogo} alt="" className="mobile-logo" />
                </NavLink>

                <div className="header-right-container">
                    {/* <div onClick={logout} role="button" className="nav-link">
                        <button className="btn btn-primary">
                            <span
                                className="px-3
                            "
                            >
                                Sign out
                            </span>
                            <img src={SignOut} alt="" />
                        </button>
                    </div> */}
                    <div className="header-date">{dateNow}</div>
                    {hasRole(RolesEnum.USER) && user?.isInvested && (
                        <div className="icon-item active">
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={popover}
                                onToggle={(nextShow) => setOverlay(nextShow)}
                                show={isOverlay}
                                rootClose
                            >
                                <div className="bell-wrapper">
                                    <Bell color="#6b7080" size="1.5em" role="button" />
                                    {inboxAlerts.length > 0 && (
                                        <div
                                            className="bell-badge"
                                            style={{ backgroundColor: inboxColors[inboxAlerts[inboxAlerts.length - 1]?.type] }}
                                        ></div>
                                    )}
                                </div>
                            </OverlayTrigger>
                        </div>
                    )}
                    <NavLink to={''} onClick={handleClick} exact activeClassName="icon-item-active" className="icon-item active">
                        {/* <CommingSoon> */}
                        {/* <Gear color="#6b7080" size="1.5em" role="button" /> */}
                        {/* </CommingSoon> */}
                    </NavLink>

                    {hasRole(RolesEnum.USER) && (
                        <NavLink to={ClientDashboardRoutes.WITHDRAWAL} exact activeClassName="icon-item-active" className="icon-item active">
                            {/* <Wallet2 color="#6b7080" size="1.5em" role="button" /> */}
                            <img src={withdrawMatch ? WalletActiveIcon : WalletIcon} />
                        </NavLink>
                    )}
                </div>
            </header>
        </>
    );
};

export default BaseHeader;
